/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/TrainingProgramStepResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/TrainingProgramStepResponse.json';

/**
 * Steps are containers in a program and contain learning objects.
 */
export interface TrainingProgramStepResponse {
  /**
   * The unique identifier of the training program step.
   */
  id: string;
  /**
   * The title of the step.
   */
  title: string;
  /**
   * The description of the step.
   */
  description?: string;
  /**
   * The estimated duration of the step in seconds. Computed from the learning objects estimated duration part of the step
   */
  estimatedDuration: number | null;
  /**
   * The date and time when the step was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  createdAt: string;
  /**
   * The date and time when the step was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   *
   */
  updatedAt: string;
  /**
   * List of learning objects part of this step
   */
  learningObjects: {
    /**
     * Boolean value that specifies if this Learning Object is mandatory or not
     */
    isMandatory: boolean;
    /**
     * Object containing the payload to create one learning object.
     * AND
     * A HATEAOS-compliant object containing only the 'self' reference.
     * AND
     * Object containing one learning object.
     */
    learningObject: {
      /**
       * The type (category) of the learning object.
       */
      type: 'ASSESSMENT' | 'AUDIO' | 'DOCUMENT' | 'INTERACTIVE' | 'PICTURE' | 'TRAINING' | 'VIDEO' | 'WEBSITE';
      /**
       * The title of the learning object.
       */
      title: string;
      /**
       * The description of the learning object.
       */
      description: string | null;
      /**
       * The estimated duration of the learning object in seconds.
       */
      estimatedDuration: number | null;
      /**
       * The language tag of the learning object, according to [BCP 47](https://www.techonthenet.com/js/language_tags.php). It is typically composed by two ISO 639-1 alphanumeric letters in lower-case (representing the language itself), a hyphen and two (or three) ISO 3166-1 alphanumeric letters in upper-case (representing the country variation).
       *
       */
      languageTag?: string;
      /**
       * The version of the learning object.
       */
      version?: number;
      /**
       * The runtime (technical execution type) of the learning object.
       */
      runtime?:
      | 'ASSESSMENT'
      | 'AUDIO_MP3'
      | 'DISC'
      | 'DOCUMENT_PDF'
      | 'DOCUMENT_EDITORIAL'
      | 'INTERACTIVE_ASSESS_AND_TEST'
      | 'INTERACTIVE_SCORM'
      | 'LPI360'
      | 'PICTURE_FILE'
      | 'TRAINING_BLENDEDX'
      | 'VIDEO_MP4'
      | 'VIDEO_YOUTUBE'
      | 'WEBSITE_URL';
      /**
       * A common reference identifier to group multiple learning objects.
       */
      referenceCode?: string | null;
      /**
       * The thumbnail URL of the learning object.
       */
      thumbnailPath: string | null;
      /**
       * The list of tags that categorize the learning object.
       */
      tags?: string[];
      /**
       * The list of authors of the learning object.
       */
      authors?: string[];
      /**
       * The metadata related to the learning object, which varies according to the runtime. It is stored as a  key-value object, containing attributes with mixed types (can be also another nested array/object) to retrieve additional information to launch the learning resource (mostly technical data).
       *
       */
      metadata?:
      | {
        /**
             * Object containing the paths to the files related to the video.
             */
        filepaths?: {
          /**
               * The path to the high quality version MP4 file.
               */
          video_high_quality?: string;
          /**
               * The path to the low quality version MP4 file.
               */
          video_low_quality?: string;
          /**
               * The path to the subtitles file.
               */
          subtitles?: string;
          [k: string]: unknown;
        };
        [k: string]: unknown;
      }
      | {
        /**
             * The path to the PDF file.
             */
        filepath?: string;
        [k: string]: unknown;
      }
      | {
        /**
             * The URL of the website.
             */
        url?: string;
        [k: string]: unknown;
      };
      /**
       * The set of HAL references to navigate from the current object. For other relations and/or their definitions, please [check this documentation](https://www.iana.org/assignments/link-relations/link-relations.xhtml).
       *
       */
      _links?: {
        /**
         * The reference for a related endpoint (from a HATEOAS property).
         */
        self?: {
          /**
           * The absolute URL of the relation endpoint.
           */
          href?: string;
          [k: string]: unknown;
        };
        [k: string]: unknown;
      };
      /**
       * The unique identifier of the training program.
       */
      id: string;
      /**
       * The date and time when the learning object was created in the service, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       *
       */
      createdAt?: string;
      /**
       * The date and time when the learning object was last updated in the service, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       *
       */
      lastUpdatedAt?: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export const validateTrainingProgramStepResponse = (data: any): data is TrainingProgramStepResponse => ajv.validate(schema, data);

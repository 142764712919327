import { useEffect, useState } from 'react';

import {
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COUNTDOWN_TIMER, TIME_FOR_IDLE } from 'src/constants/idleTimer';
import './DisconnectSessionDialog.scss';

import AlertDialog from '../AlertDialog/AlertDialog';

interface Props {
  reset: () => void;
  logoutUser: () => void;
}

function DisconnectSessionDialog({
  reset, logoutUser,
}: Props) {
  const countDownTimeInSeconds = COUNTDOWN_TIMER; // seconds, 1 minute and 30 seconds
  const timeForIdleSession = TIME_FOR_IDLE;
  const navigate = useNavigate();
  const [secondsAmount, setSecondsAmount] = useState(countDownTimeInSeconds);
  const { t } = useTranslation('common');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (secondsAmount > 0) {
      timeoutId = setTimeout(() => {
        setSecondsAmount((state) => state - 1);
      }, 1000);
    } else {
      navigate({
        pathname: '/disconnected',
      });
      logoutUser();
    }
    return () => timeoutId && clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsAmount]);

  const minutes = Math.floor(secondsAmount / 60);
  const seconds = secondsAmount % 60;

  // Refresh token and reset idle timer
  const handleIamHere = () => {
    reset();
    setSecondsAmount(countDownTimeInSeconds);
  };

  // logout the user, clean the tokens and redirect to blank page for now.
  const handleSignOut = () => {
    logoutUser();
    navigate({
      pathname: '/disconnected',
    });
    window.location.reload();
  };

  return (
    <AlertDialog
      title={t('dialogIdle.title')}
      description={t('dialogIdle.description', { timeForIdleSession })}
      cancelButtonText={t('dialogIdle.signOut')}
      onCancelPress={handleSignOut}
      confirmButtonText={t('dialogIdle.imHere')}
      onConfirmPress={handleIamHere}
    >
      <Typography className="DisconnectSessionDialog-timer">
        <b>
          {minutes.toString().padStart(2, '0')}

          {' min '}

          {seconds.toString().padStart(2, '0')}

          {' sec '}
        </b>

        {t('dialogIdle.timerRemaining')}
      </Typography>
    </AlertDialog>
  );
}

export default DisconnectSessionDialog;

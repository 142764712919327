import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { fetchEvents, fetchEventsCaseHandlers } from './thunks';
import { EventListState } from './types';

const initialState: EventListState = {
  status: Status.IDLE,
  events: [],
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetch events
      .addCase(fetchEvents.pending, fetchEventsCaseHandlers.handlePending)
      .addCase(fetchEvents.fulfilled, fetchEventsCaseHandlers.handleFulfilled)
      .addCase(fetchEvents.rejected, fetchEventsCaseHandlers.handleRejected);
  },
});

export default eventsSlice.reducer;

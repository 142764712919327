import { Status } from './Status';

export type CsvLearner = { email: string };

export type Learner = {
  enrollmentId?: string,
  name?: string,
  email: string,
  alreadyEnrolled?: boolean
};

export enum AssignmentModalMode {
  ASSIGN_LEARNERS = 'assignLearners',
  ASSIGNED_LEARNERS = 'assignedLearners',
}

export type EnrollmentsState = {
  status: Status,
  modalOpen: boolean,
  modalMode?: AssignmentModalMode
  modalLoading: boolean,
  learnersEnrolled: Learner[],
  assignedLearners: Learner[],
  trainingProgramId?: string
};

/* eslint-disable no-await-in-loop */
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrainingProgramStepResponse } from 'src/types/requests/TrainingPrograms';
import { Status } from 'src/types/Status';
import { StepData } from 'src/types/TrainingProgram';
import { FlowReturn } from 'src/types/utils';

import {
  postTrainingProgramStep, postLearningObjectToStep, deleteLearningObjectFromStep,
  getTrainingProgramStep, putTrainingProgramStep, deleteTrainingProgramStep,
} from './api';
import type { RootState } from '../../store';
import { TrainingProgramState } from '../types';

/**
 * Fetch the step by id and set it to current program
 */
export const fetchStep = createAsyncThunk(
  'trainingProgram/fetchStep',
  async ({ stepId, order }: { stepId: string, order?: number }, { rejectWithValue, getState }) => {
    const { auth, trainingProgram } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    const { currentProgram } = trainingProgram;
    if (!currentProgram || !currentProgram.id) {
      return rejectWithValue('Current program does not exisits or is yet to be created');
    }

    type StepResponse = FlowReturn<typeof getTrainingProgramStep>;
    try {
      const response: StepResponse = await getTrainingProgramStep(
        currentProgram.id,
        stepId,
        auth.tokens.accessToken,
      );
      if (!response) {
        return rejectWithValue('Failed to get Training Program');
      }

      return { ...response, order: order || 1 };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const fetchStepCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.stepStatus = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.stepStatus = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.stepStatus = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

export const createStep = createAsyncThunk(
  'trainingProgram/createStep',
  async ({ title, description, learningObjectList }: StepData, { rejectWithValue, getState }) => {
    const { auth, trainingProgram } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    const { currentProgram } = trainingProgram;
    if (!currentProgram || !currentProgram.id) {
      return rejectWithValue('Current program does not exisits or is yet to be created');
    }

    type StepResponse = FlowReturn<typeof postTrainingProgramStep>;
    try {
      const response: StepResponse = await postTrainingProgramStep(
        { title, description },
        currentProgram.id,
        auth.tokens.accessToken,
      );
      if (!response) return rejectWithValue('Failed to create step');

      for (const contentId of learningObjectList.learningObjectsToAdd) {
        await postLearningObjectToStep(
          currentProgram?.id as string,
          response.id,
          contentId,
          auth.tokens?.accessToken as string,
        );
      }

      return { ...response, order: currentProgram.steps?.length || 1 };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const createStepCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.stepStatus = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramStepResponse>) => {
    if (!state.currentProgram) return;

    state.firstStepCreated = true;

    if (state.currentProgram.steps) {
      state.currentProgram.steps.push(payload);
    } else {
      state.currentProgram.steps = [payload];
    }

    state.stepStatus = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    console.error(payload);
    state.stepStatus = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

export const updateStep = createAsyncThunk(
  'trainingProgram/updateStep',
  async ({
    id, title, description, learningObjectList,
  }: StepData, { getState, rejectWithValue }) => {
    const { auth, trainingProgram } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }

    const { currentProgram } = trainingProgram;

    if (!currentProgram || !currentProgram.id || !currentProgram.steps) {
      return rejectWithValue('Current program does not exist, is yet to be created or does not have steps.');
    }

    const stepIdx = currentProgram.steps.findIndex((step) => step.id === id);
    if (stepIdx === -1) {
      return rejectWithValue(`Can not find step with id ${id}`);
    }
    try {
      for (const contentId of learningObjectList.learningObjectsToAdd) {
        await postLearningObjectToStep(
          currentProgram?.id as string,
          id as string,
          contentId,
          auth.tokens?.accessToken as string,
        );
      }
      for (const contentId of learningObjectList.learningObjectsToRemove) {
        await deleteLearningObjectFromStep(
          currentProgram?.id as string,
          id as string,
          contentId,
          auth.tokens?.accessToken as string,
        );
      }

    type StepResponse = FlowReturn<typeof putTrainingProgramStep>;
    const response: StepResponse = await putTrainingProgramStep(
      currentProgram.id,
      id as string,
      auth.tokens.accessToken,
      { title, description, learningObjectIds: learningObjectList.orderedLearningObjectsList },
    );

    if (!response) {
      return rejectWithValue('Could not update step');
    }
    return { ...response, order: stepIdx };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const updateStepCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.stepStatus = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramStepResponse>) => {
    if (!state.currentProgram || !state.currentProgram.steps) return;

    state.currentProgram.steps = state.currentProgram.steps.map((step) => {
      if (step.id === payload.id) return payload;
      return step;
    });

    state.stepStatus = Status.SUCCEEDED;
  },
  handleRejected: (state) => {
    state.stepStatus = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

export const removeStep = createAsyncThunk(
  'trainingProgram/removeStep',
  async (stepId: string, { getState, rejectWithValue }) => {
    const { auth, trainingProgram } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    const { currentProgram } = trainingProgram;

    if (!currentProgram || !currentProgram.id) {
      return rejectWithValue('Current program does not exisits or is yet to be created');
    }
    type DeleteStepResponse = FlowReturn<typeof deleteTrainingProgramStep>;
    const response: DeleteStepResponse = await deleteTrainingProgramStep(
      currentProgram.id as string,
      stepId,
      auth.tokens.accessToken as string,
    );

    if (!response) {
      return rejectWithValue('Failed to delete step');
    }

    return stepId;
  },
);

export const removeStepCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.stepStatus = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<string>) => {
    if (!state.currentProgram || !state.currentProgram.steps) return;

    const stepId = payload;

    state.currentProgram.steps = state.currentProgram.steps.filter(
      (step) => step.id !== stepId,
    );

    state.stepStatus = Status.SUCCEEDED;
  },
  handleRejected: (state) => {
    state.stepStatus = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

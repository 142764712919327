/**
 * This model is a placeholder. In future all contents will be detailed
 */

import { SnapshotOut, types } from 'mobx-state-tree';
import { DetailedLearningObjectResponse } from 'src/types/validators/DetailedLearningObjectResponse';

const {
  model, identifier, string, array, maybe, number,
} = types;

const DetailedContentModel = model('DetailedContentModel', {
  id: identifier,
  type: string,

  estimatedDuration: maybe(number),

  title: string,
  description: string,

  tags: maybe(array(string)),
  authors: maybe(array(string)),

  // Faked
  thumbnail: string,
});

export function createDetailedContentModelFromResponse(
  response: DetailedLearningObjectResponse,
) {
  return DetailedContentModel.create({
    id: response.id!.toString(),
    type: response.type!,
    estimatedDuration: response.estimatedDuration || undefined,
    title: response.title || '<no title>',
    description: response.description || '',

    tags: response.tags,
    authors: response.authors,

    thumbnail: '',
  });
}

export type DetailedContentModelSnapshot = SnapshotOut<typeof DetailedContentModel>;
export default DetailedContentModel;

import { Instance, types } from 'mobx-state-tree';

export const FilterModel = types.model('FilterModel', {
  id: types.identifier,
  name: types.string,
  value: types.string,
  checked: false,
})
  .actions((self) => ({

    check() {
      self.checked = !self.checked;
    },

  }));

export interface FilterModelInstance extends Instance<typeof FilterModel> {}

export const FilterCategory = types.model('FilterCategory', {
  name: types.identifier,
  entries: types.array(FilterModel),
})
  .views((self) => ({

    get checkedNames() {
      return self.entries
        .filter((entry) => entry.checked)
        .map((item) => item.value);
    },

    get selectedCount() {
      let count = 0;
      for (const entry of self.entries) {
        if (entry.checked) {
          count += 1;
        }
      }
      return count;
    },

  }));

import { types } from 'mobx-state-tree';

export const PaginationStore = types.model('PaginationStore', {
  currentPage: 1,
  pageCount: 10,
})
  .actions((self) => ({
    setPageCount(count: number) {
      self.pageCount = count;
    },

    onPage(page: number) {
      self.currentPage = page;
      if (page > self.pageCount) self.currentPage = self.pageCount;
      if (page < 1) self.currentPage = 1;
    },

  }));

export function createPaginationStore() {
  return {};
}

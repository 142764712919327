/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/EventList.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/EventList.json';

/**
 * Object with a list of events
 */
export interface EventListResponse {
  /**
   * List of events
   */
  items?: {
    /**
     * The unique identifier of the event
     */
    id: string;
    /**
     * The unique identifier of the cohort
     */
    cohortId?: string;
    /**
     * The unique identifier of the training program
     */
    trainingProgramId?: string;
    /**
     * The unique identifier of the organization
     */
    organizerId?: string;
    /**
     * Type of the event
     */
    type: string;
    /**
     * Description of objective of the event
     */
    objectives: string;
    /**
     * Title of the event
     */
    title: string;
    /**
     * Virtual location where the event is taking place
     */
    location: {
      /**
       * The platform choose to host the event
       */
      type: 'MS_TEAMS' | 'SLACK' | 'GOOGLE_MEET' | 'ZOOM' | 'IN_LOCATION';
      /**
       * URL to the virtual room
       */
      link: string;
      /**
       * Adicional information about the event location
       */
      description: string;
      [k: string]: unknown;
    };
    /**
     * Exact date and time scheduled for the event to start
     */
    startAt: string;
    /**
     * Exact date and time scheduled for the event to end
     */
    endAt: string;
    /**
     * Timezone of the event
     */
    timezone: string;
    /**
     * The date and time when the event was created, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     *
     */
    createdAt?: string;
    /**
     * The date and time when the event was last updated, represented in the ISO 8601 GMT format (datetime) according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     *
     */
    updatedAt?: string;
    [k: string]: unknown;
  }[];
  count?: number;
  /**
   * The set of HAL references to navigate from the current object. For other relations and/or their definitions, please [check this documentation](https://www.iana.org/assignments/link-relations/link-relations.xhtml).
   *
   */
  _links?: {
    /**
     * The reference for a related endpoint (from a HATEOAS property).
     */
    self?: {
      /**
       * The absolute URL of the relation endpoint.
       */
      href?: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export const validateEventListResponse = (data: any): data is EventListResponse => ajv.validate(schema, data);

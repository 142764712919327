import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import ConnectLoading from 'src/components/@common/ConnectLoading/ConnectLoading';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { switchActiveRole } from 'src/redux/user/slice';
import { useStore } from 'src/store';
import { Status } from 'src/types/Status';

interface ProtectedRouteProps {
  requiresAuth: boolean,
  allowedRoles: string[] | '*',
  redirectPath?: string
}

const ProtectedRoute = observer(({
  requiresAuth, allowedRoles = [],
}: ProtectedRouteProps) => {
  const { t } = useTranslation('error');
  const user = useAppSelector((state) => state.user);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const store = useStore();

  // This is needed because we did't remove the mobx yet, so we need to add 
  // the tokens and the user on the mobx also
  useEffect(() => {
    // adding auth data from Redux to MST
    if (auth.tokens && !store.auth.tokens) {
      store.auth.addTokensFromRedux(auth.tokens);
    }

    if (user.user && !store.auth.user) {
      store.auth.addUserFromRedux(user.user);
    }
  }, [auth.tokens, store.auth, user.user, dispatch, store.auth.user]);

  if (!requiresAuth) return <Outlet />;

  if (
    !auth.initialized
    || auth.status === Status.LOADING
    || user.status === Status.LOADING
  ) return <ConnectLoading />;

  if (auth.status === Status.FAILED || user.status === Status.FAILED || !user.user) {
    return <ErrorPage message={t('auth')} />;
  }

  const userRolesAllowed = user.user.roles.filter((role) => allowedRoles.includes(role));
  const allowUser = allowedRoles === '*' || userRolesAllowed.length;

  if (!allowUser) {
    return <ErrorPage message={t('unallowedRole')} />;
  }

  /**
   * if user has an allowed role but an unallowed role is active,
   * switch active role to the first allowed role in the user roles array
   */
  if (!userRolesAllowed.includes(user.user.activeRole)) {
    dispatch(switchActiveRole(userRolesAllowed[0]));
  }

  return <Outlet />;
});

export default ProtectedRoute;

import React from 'react';

import { UserRoles } from 'src/types/roles';

const AuthPage = React.lazy(() => import('src/components/AuthPage/AuthPage'));

const LoginPage = React.lazy(() => import('src/components/LoginPage/LoginPage'));
const DisconnectedPage = React.lazy(() => import('src/components/DisconnectedPage/DisconnectedPage'));
const ForgotPasswordPage = React.lazy(() => import('src/components/ForgotPasswordPage/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('src/components/ResetPasswordPage/ResetPasswordPage'));

const ErrorPage = React.lazy(() => import('src/components/ErrorPage/ErrorPage'));
const PageNotFound = React.lazy(() => import('src/components/PageNotFound/PageNotFound'));

const TrainingProgramListPage = React.lazy(() => import('src/components/TrainingProgramListPage'));
const ProgramPage = React.lazy(() => import('src/components/ProgramPage/ProgramPage'));
const CreateProgramPage = React.lazy(() => import('src/components/CreateProgramPage/CreateProgramPage'));

const ReportListPage = React.lazy(() => import('src/components/ReportListPage/ReportListPage'));
const ReportPage = React.lazy(() => import('src/components/ReportPage/ReportPage'));

const FacilitatorHomePage = React.lazy(() => import('src/components/FacilitatorHomePage/FacilitatorHomePage'));
const FacilitatorEventPage = React.lazy(() => import('src/components/FacilitatorEventPage/FacilitatorEventPage'));

interface UnprotectedRouteDefinition {
  name: string,
  path: string,
  renderComponent: Function,
  requiresAuth: false
}

interface ProtectedRouteDefinition {
  name: string,
  path: string,
  renderComponent: Function,
  requiresAuth: true,
  allowedRoles: string[] | '*',
  allowedPermissions: string[] | '*'
}

type RouteDefinition = UnprotectedRouteDefinition | ProtectedRouteDefinition;

export const ROUTES = {
  loginPage: 'loginPage',

  auth: 'auth',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  disconnected: 'disconnected',

  error: 'error',
  notFound: 'notFound',

  trainingProgramList: 'trainingProgramList',
  trainingProgram: 'trainingProgram',
  createTrainingProgram: 'createTrainingProgram',

  reportList: 'reportList',
  report: 'report',
  facilitator: 'facilitator',
  event: 'event',
};

export const ROUTES_DEFINITIONS: { [key: string]: RouteDefinition } = {
  // auth
  [ROUTES.auth]: {
    name: ROUTES.auth,
    path: '/ckls/auth',
    renderComponent: () => <AuthPage />,
    requiresAuth: false,
  },
  [ROUTES.forgotPassword]: {
    name: ROUTES.forgotPassword,
    path: '/forgot-password',
    renderComponent: () => <ForgotPasswordPage />,
    requiresAuth: false,
  },
  [ROUTES.resetPassword]: {
    name: ROUTES.resetPassword,
    path: '/reset-password',
    renderComponent: () => <ResetPasswordPage />,
    requiresAuth: false,
  },
  [ROUTES.disconnected]: {
    name: ROUTES.disconnected,
    path: '/disconnected',
    renderComponent: () => <DisconnectedPage />,
    requiresAuth: false,
  },

  // common
  [ROUTES.error]: {
    name: ROUTES.error,
    path: '/error',
    renderComponent: () => <ErrorPage />,
    requiresAuth: false,
  },

  // training program
  [ROUTES.trainingProgramList]: {
    name: ROUTES.trainingProgramList,
    path: '/training-programs',
    renderComponent: () => <TrainingProgramListPage />,
    requiresAuth: true,
    allowedRoles: [
      UserRoles.LEARNER,
      UserRoles.LEARNING_DESIGNER,
      UserRoles.ADMIN,
    ],
    allowedPermissions: '*',
  },
  [ROUTES.trainingProgram]: {
    name: ROUTES.trainingProgram,
    path: '/training-programs/:programId',
    renderComponent: () => <ProgramPage />,
    requiresAuth: true,
    allowedRoles: [
      UserRoles.LEARNER,
      UserRoles.LEARNING_DESIGNER,
      UserRoles.ADMIN,
    ],
    allowedPermissions: '*',
  },
  [ROUTES.createTrainingProgram]: {
    name: ROUTES.createTrainingProgram,
    path: '/training-programs/new',
    renderComponent: () => <CreateProgramPage />,
    requiresAuth: true,
    allowedRoles: [
      UserRoles.LEARNING_DESIGNER,
      UserRoles.ADMIN,
    ],
    allowedPermissions: '*',
  },

  // reports
  [ROUTES.reportList]: {
    name: ROUTES.reportList,
    path: '/reports',
    renderComponent: () => <ReportListPage />,
    requiresAuth: true,
    allowedRoles: [UserRoles.ADMIN],
    allowedPermissions: '*',
  },
  [ROUTES.report]: {
    name: ROUTES.report,
    path: '/reports/:reportRef',
    renderComponent: () => <ReportPage />,
    requiresAuth: true,
    allowedRoles: [UserRoles.ADMIN],
    allowedPermissions: '*',
  },

  // Facilitator
  [ROUTES.facilitator]: {
    name: ROUTES.facilitator,
    path: '/facilitator/home',
    renderComponent: () => <FacilitatorHomePage />,
    requiresAuth: true,
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.FACILITATOR,
    ],
    allowedPermissions: '*',
  },
  [ROUTES.event]: {
    name: ROUTES.event,
    path: '/facilitator/event',
    renderComponent: () => <FacilitatorEventPage />,
    requiresAuth: true,
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.FACILITATOR,
      UserRoles.LEARNER,
    ],
    allowedPermissions: '*',
  },

  [ROUTES.loginPage]: {
    name: ROUTES.loginPage,
    path: '/',
    renderComponent: () => <LoginPage />,
    requiresAuth: false,
  },

  [ROUTES.notFound]: {
    name: ROUTES.notFound,
    path: '/not-found',
    renderComponent: () => <PageNotFound />,
    requiresAuth: false,
  },
};

type RouteParams = { [key: string]: string };
export const reverseRoute = (routeName: string, params?: RouteParams): string => {
  let routePath = ROUTES_DEFINITIONS[routeName].path;
  if (params) {
    Object.entries(params).forEach(([paramKey, paramValue]) => {
      routePath = routePath.replace(`:${paramKey}`, paramValue);
    });
  }

  return routePath;
};

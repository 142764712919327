import { useContext } from 'react';

import { RootContext } from './provider';

/**
 * Get access to store from any component
 * Should be used only in pages
 */
export default function useStore() {
  const store = useContext(RootContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
}

export default [
  {
    name: 'filters.categories.type',
    entries: [
      { name: 'filters.types.training', id: '1', value: 'TYPE_TRAINING' },
      { name: 'filters.types.video', id: '2', value: 'TYPE_VIDEO' },
      { name: 'filters.types.interactive', id: '3', value: 'TYPE_INTERACTIVE' },
      { name: 'filters.types.document', id: '4', value: 'TYPE_DOCUMENT' },
      { name: 'filters.types.website', id: '5', value: 'TYPE_WEBSITE' },
      { name: 'filters.types.picture', id: '6', value: 'TYPE_PICTURE' },
      { name: 'filters.types.audio', id: '7', value: 'TYPE_AUDIO' },
    ],
  },

];

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { getPowerBiAccess, getPowerBiAccessCaseHandlers } from './thunks';
import { Report, ReportsState } from './types';
import type { RootState } from '../store';

export const initialState: ReportsState = {
  status: Status.IDLE,
  currentReport: null,
  reportAccessConfig: undefined,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setCurrentReport(state, { payload }: PayloadAction<Report>) {
      state.currentReport = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getPowerBiAccess
      .addCase(getPowerBiAccess.pending, getPowerBiAccessCaseHandlers.handlePending)
      .addCase(getPowerBiAccess.fulfilled, getPowerBiAccessCaseHandlers.handleFulfilled)
      .addCase(getPowerBiAccess.rejected, getPowerBiAccessCaseHandlers.handleRejected);
  },
});

export default reportsSlice.reducer;

export const selectReports = (state: RootState) => state.reports;

export const { setCurrentReport } = reportsSlice.actions;

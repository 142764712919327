import jwt_decode from 'jwt-decode';

interface DecodedToken {
  ace: string,
  aud: string,
  azp: string,
  email: string,
  email_verified: boolean,
  exp: number,
  iat: number,
  iss: string,
  jti: string,
  pendoUserUuid: string,
  keycloakId: string,
  preferred_username: string,
  realm_access: {
    roles: string[],
  },
  resource_access: {
    account: string[],
  },
  scope: string,
  session_state: string,
  sid: string,
  user_id: string,
  sub: string,
  typ: string,
}

export default function decodeToken(token: string): Partial<DecodedToken> {
  return jwt_decode(token);
}

// This store is deprecated, don't use this store anymore, use Redux insetead /src/redux files
// This file should be removed when mobx is removed completely

// import { reaction } from 'mobx';
import {
  cast, flow, Instance, types,
} from 'mobx-state-tree';
import {
  KEY_ACCESS_TOKEN, KEY_EXPIRES_IN, KEY_REFRESH_TOKEN, KEY_TOKEN_TYPE,
} from 'src/constants/api';
import { KEY_TOKENS } from 'src/constants/localStorage';
import decodeToken from 'src/helpers/jwt';
import { TokensModel as TokensModelRedux } from 'src/redux/auth/types';
import { UserModel as UserModelRedux } from 'src/redux/user/types';
import { getTokens, refreshTokens } from 'src/services/api/authService';
import {
  startTimer, stopTimer, isTimerPending, isTokenExpired,
} from 'src/services/api/refreshTokensTimer';
import getUserInfo, { getOrganizationRoles, getOriginalExperienceAutoConnectionUri } from 'src/services/api/user';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';
// import suspenseNotifier from 'src/store/suspenseNotifier';
import { LoggedUser } from 'src/types/LoggedUser';
import { OpenIdConnectTokenResponse } from 'src/types/requests/OpenIdConnectTokenResponse';
import { UserRoles } from 'src/types/roles';
import { FlowReturn } from 'src/types/utils';
import { Role } from 'src/types/validators/OrganizationRolesResponse';
import { UserDataResponse } from 'src/types/validators/UserDataResponse';

import TokensModel from './TokensModel';
import UserModel from './UserModel';

const AuthStore = types.model('AuthStore', {
  id: types.identifier,
  user: types.maybe(UserModel),
  tokens: types.maybe(TokensModel),
  code: types.maybe(types.string),
  redirectUri: types.maybe(types.string),
  ckPlatformUri: types.maybe(types.string),
  error: types.maybe(types.boolean),
  idleDialogEnabled: false,
  loading: false,
  // This temporary state value should be in user once organization roles api is fully ready
  organizationIds: types.array(types.number),
})
  .actions((self) => ({
    addUserFromJsonResponse(jsonResponse: UserDataResponse, roles?: UserRoles[]) {
      self.user = UserModel.create({
        id: jsonResponse.id,
        firstName: jsonResponse.firstName,
        lastName: jsonResponse.lastName,
        email: jsonResponse.username,
        avatarUrl: jsonResponse.avatarUrl || undefined,
        roles: roles || [],
        activeRole: roles?.length ? roles[0] : '',
      });
      self.idleDialogEnabled = true;
    },
    addUserFromRedux(user: UserModelRedux) {
      self.user = UserModel.create({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        avatarUrl: user.avatarUrl,
        roles: user.roles,
        activeRole: user.activeRole,
      });
      self.idleDialogEnabled = true;
    },
    addTokensFromRedux(auth: TokensModelRedux) {
      self.tokens = TokensModel.create({
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken,
        expiresIn: auth.expiresIn,
        tokenType: auth.tokenType,
      });
    },
    addTokensFromJsonResponse(jsonResponse: OpenIdConnectTokenResponse) {
      localStorage.setItem(KEY_TOKENS, JSON.stringify(jsonResponse));

      self.tokens = TokensModel.create({
        accessToken: jsonResponse[KEY_ACCESS_TOKEN],
        refreshToken: jsonResponse[KEY_REFRESH_TOKEN],
        expiresIn: jsonResponse[KEY_EXPIRES_IN],
        tokenType: jsonResponse[KEY_TOKEN_TYPE],
      });
    },

    initPendo(visitorId: string) {
      MeasurementsClient.init(visitorId);
    },

    setCode(code: string) {
      self.code = code;
    },

    setRedirectUri(redirectUri: string) {
      self.redirectUri = redirectUri;
    },

    setCkPlatformUri(ckPlatformUri: string | undefined) {
      self.ckPlatformUri = ckPlatformUri;
    },

    // Temporary solution for organization ids
    setOrganizationIds(organizationIds: number[]) {
      self.organizationIds = cast(organizationIds);
    },

    setUser(user: LoggedUser) {
      self.user = cast(user);
    },

    setError(error?: boolean) {
      self.error = error;
    },

    switchActiveRole(roleName: string) {
      if (self.user) {
        const foundRole = self.user.roles.find((role) => role === roleName);
        if (foundRole) {
          self.user.activeRole = foundRole;
        } else {
          console.error('User is not allowed to desired role');
        }
      }
    },
  }))
  .actions((self) => ({
    logoutUser() {
      self.user = undefined;
      self.tokens = undefined;
      self.error = undefined;
      self.loading = false;
      localStorage.removeItem(KEY_TOKENS);
      // important after remove tokens from storage
      stopTimer();
    },
  }))
  .actions((self) => ({
    refreshTokens: flow(function* refreshTokensAction() {
      self.error = false;
      if (!self.tokens) {
        self.error = true;
        console.error('Not found tokens.');
        return;
      }

      const { accessToken, refreshToken } = self.tokens;
      if (!accessToken || !refreshToken) {
        self.error = true;
        console.error('Not found access and refresh tokens.');
        return;
      }

      const decoded = decodeToken(accessToken);
      if (!decoded.iss) {
        self.error = true;
        console.error('Unable to refresh token. "iss" field not found in decoded token', decoded);
        return;
      }

      if (!decoded.exp) {
        self.error = true;
        console.error('Unable to refresh token. "exp" field not found in decoded token', decoded);
        return;
      }

      if (isTokenExpired(decoded.exp)) {
        self.logoutUser();
        self.error = true;
        console.error('Unable to refresh token. Token is expired');
        return;
      }

      type Response = FlowReturn<typeof refreshTokens>;
      const response: Response = yield refreshTokens(decoded.iss, refreshToken);
      if (!response) {
        self.error = true;
        console.error('The refresh tokens response is not received');
        return;
      }

      self.addTokensFromJsonResponse(response);
    }),
  }))
  .actions((self) => ({
    fetchUser: flow(function* fetchUser(id: string) {
      if (!self.tokens) {
        console.error('Missing tokens.');
        return;
      }

      const { accessToken } = self.tokens;
      if (!accessToken) {
        console.error('Not found access token.');
        return;
      }

      type UserInfo = FlowReturn<typeof getUserInfo>;
      const response: UserInfo = yield getUserInfo(id, accessToken);
      if (!response) {
        console.error('The UserInfo response is not received.');
        return;
      }

      type OrganizationRoles = FlowReturn<typeof getOrganizationRoles>;
      const organizationRoles: OrganizationRoles = yield getOrganizationRoles(id, accessToken);
      if (!organizationRoles || !organizationRoles.items || !organizationRoles.items.length) {
        console.error('The organization roles are not retrieved.');
        return;
      }

      // Retrieve available roles
      const rolesArr = organizationRoles.items.reduce(
        (acc: Role[], organizationRole) => {
          acc.push(organizationRole.role);
          return acc;
        },
        [],
      );

      let roles = rolesArr.map((role) => {
        switch (role) {
          case Role.LEARNER:
            return UserRoles.LEARNER;
          case Role.ORG_ADMIN:
            return UserRoles.ADMIN;
          case Role.INSTRUCTIONAL_DESIGNER:
            return UserRoles.LEARNING_DESIGNER;
          case Role.FACILITATOR:
            return UserRoles.FACILITATOR;
          default:
            return UserRoles.LEARNER;
        }
      });
      // Remove duplicated roles
      roles = [...new Set(roles)];

      self.addUserFromJsonResponse(response, roles);

      const organizationIds = organizationRoles.items.map((role) => role.organizationId) as number[];
      self.setOrganizationIds(organizationIds);
    }),
  }))
  .actions((self) => ({
    fetchTokens: flow(function* fetchTokens() {
      self.loading = true;
      self.error = false;

      if (!self.code || !self.redirectUri) {
        self.error = true;
        self.loading = false;
        console.error('Not found code and redirectUri.');
        return;
      }

      type Response = FlowReturn<typeof getTokens>;
      const response: Response = yield getTokens(self.code, self.redirectUri);
      if (!response?.access_token) {
        self.error = true;
        self.loading = false;
        console.error('Unable to fetch tokens. "access_token" field not found in response.');
        return;
      }

      self.addTokensFromJsonResponse(response);

      self.code = undefined;
      self.redirectUri = undefined;

      // Get sub from token & fetch user
      const decoded = decodeToken(response.access_token);
      if (decoded.user_id) {
        self.fetchUser(decoded.user_id);
        self.loading = false;
      } else {
        console.error('Unable to fetch user. "sub" field not found in decoded token.', decoded);
        self.loading = false;
      }

      // if (decoded.pendoUserUuid) {
      //   self.initPendo(decoded.pendoUserUuid);
      // } else {
      //   console.warn('Unable to init pendo. "pendoUserUuid" field not found in decoded token', decoded);
      // }
    }),
  }))

  // action for clear user data and clean token from session
  .actions((self) => ({
    disableIdleDialog() {
      self.idleDialogEnabled = false;
    },
    enableIdleDialog() {
      self.idleDialogEnabled = true;
    },
  }))
  .views((self) => ({
    get isUserAuthenticated() {
      return !!self.tokens;
    },
    get isIdleDialogEnabled() {
      return !!self.idleDialogEnabled;
    },
  }))
  .actions((self) => ({
    delayBeforeRefreshTokens: flow(function* delayBeforeRefreshTokens() {
      if (isTimerPending()) {
        return;
      }

      if (!self.tokens) {
        return;
      }

      const { accessToken } = self.tokens;
      if (!accessToken) {
        console.error('Access token not found.');
        return;
      }

      const decoded = decodeToken(accessToken);
      if (!decoded.exp) {
        console.error('Unable to refresh token. "exp" field not found in decoded token', decoded);
        return;
      }

      type Response = FlowReturn<typeof startTimer>;
      const result: Response = yield startTimer(decoded.exp);
      if (result) {
        self.refreshTokens();
      }
    }),
    retrieveTokensFromLocalStorage() {
      const localStorageTokens = localStorage.getItem(KEY_TOKENS);

      if (!localStorageTokens) return;

      const tokens: OpenIdConnectTokenResponse = JSON.parse(localStorageTokens);

      if (!tokens.access_token) {
        self.tokens = undefined;
      }

      self.addTokensFromJsonResponse(tokens);
    },
  }))
  // .actions((self) => ({
  //   afterCreate() {
  //     reaction(
  //       () => self.tokens,
  //       () => self.delayBeforeRefreshTokens(),
  //     );
  //   },
  // }))

  // switch experience actions & views
  .actions((self) => ({
    fetchOriginalExperienceUri: flow(function* fetchOriginalExperienceUri() {
      if (!self.tokens) {
        console.error('Missing tokens.');

        return;
      }

      const { accessToken } = self.tokens;
      if (!accessToken) {
        console.error('Not found access token.');

        return;
      }

      if (!self.user) {
        console.error('Missing user.');

        return;
      }

      if (!self.user.id) {
        console.error('Missing user id.');

        return;
      }

      type OriginalExperienceAutoConnectionUri = FlowReturn<typeof getOriginalExperienceAutoConnectionUri>;
      let response: OriginalExperienceAutoConnectionUri;
      try {
        response = yield getOriginalExperienceAutoConnectionUri(
          self.user.id.toString(),
          accessToken,
        );
      } catch (err) {
        console.error('Failed to fetch original experience auto connection uri', err);
        return;
      }

      if (!response) {
        console.error('The OriginalExperienceAutoConnectionUri response is not received.');

        return;
      }

      return response.autoConnectionUri;
    }),
  }))
  .actions((self) => ({
    authenticate: flow(function* authenticate() {
      self.loading = true;

      if (self.code && self.redirectUri) {
        yield self.fetchTokens();
      }

      if (!self.tokens || !self.tokens.accessToken) {
        self.retrieveTokensFromLocalStorage();

        if (!self.tokens?.accessToken) {
          self.error = true;
          self.loading = false;
          return;
        }
      }

      let decoded;
      try {
        decoded = decodeToken(self.tokens?.accessToken);
      } catch (e) {
        self.logoutUser();
        self.error = true;

        console.error('Unable to decode token', e);
        return;
      }

      if (!decoded.exp || !decoded.user_id) {
        self.logoutUser();
        self.error = true;

        console.error('Unable to authenticate. "exp" or "sub" field missing in token');
        return;
      }

      if (isTokenExpired(decoded.exp)) {
        self.logoutUser();
        self.error = true;

        console.error('Unable to authenticate. Token is expired');
        return;
      }

      try {
        yield self.refreshTokens();
      } finally {
        yield self.fetchUser(decoded.user_id);
        self.loading = false;
      }
    }),
  }));
  // .views((self) => ({
  //   get initialization() {
  //     return suspenseNotifier(self.authenticate());
  //   },
  // }));

export default AuthStore;

export function createAuthStore() {
  return { idleDialogEnabled: true, id: AuthStore.name };
}

export interface AuthStoreInstance extends Instance<typeof AuthStore> {}

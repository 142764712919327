export enum LearningTypes {
  ASSESSMENT = 'ASSESSMENT',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  INTERACTIVE = 'INTERACTIVE',
  PICTURE = 'PICTURE',
  TRAINING = 'TRAINING',
  VIDEO = 'VIDEO',
  WEBSITE = 'WEBSITE',
}

export enum LearningRuntime {
  ASSESSMENT = 'ASSESSMENT',
  AUDIO_MP3 = 'AUDIO_MP3',
  DISC = 'DISC',
  DOCUMENT_PDF = 'DOCUMENT_PDF',
  DOCUMENT_EDITORIAL = 'DOCUMENT_EDITORIAL',
  INTERACTIVE_ASSESS_AND_TEST = 'INTERACTIVE_ASSESS_AND_TEST',
  INTERACTIVE_SCORM = 'INTERACTIVE_SCORM',
  LPI360 = 'LPI360',
  PICTURE_FILE = 'PICTURE_FILE',
  TRAINING_BLENDEDX = 'TRAINING_BLENDEDX',
  VIDEO_MP4 = 'VIDEO_MP4',
  VIDEO_YOUTUBE = 'VIDEO_YOUTUBE',
  WEBSITE_URL = 'WEBSITE_URL',
}

export interface LearningObjectModel {
  id: string,
  type: LearningTypes,
  title: string,
  description?: string,
  estimatedDuration?: number,
  languageTag: string,
  version?: number,
  runtime: LearningRuntime,
  referenceCode?: string,
  tags?: string[],
  authors?: string[],
  metadata?: object,
  thumbnailPath?: string,
  organizationIds?: number[],
  productIds?: number[],
  isDiscoverable?: boolean,
  _links?: object,
  createdAt?: string,
  updatedAt?: string,
}

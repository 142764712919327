import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KEY_TOKENS } from 'src/constants/localStorage';
import decodeToken from 'src/helpers/jwt';
import {
  isTokenExpired, startTimer,
} from 'src/services/api/refreshTokensTimer';
import { OpenIdConnectTokenResponse } from 'src/types/requests/OpenIdConnectTokenResponse';
import { Status } from 'src/types/Status';

import {
  authenticate,
  authenticateCaseHandlers,
  refreshAccessToken,
  refreshAccessTokenCaseHandlers,
  sendRecoverPasswordEmail,
  sendRecoverPasswordEmailCaseHandlers,
  resetPassword,
  resetPasswordCaseHandlers,
} from './thunks';
import { AuthState, TokensModel } from './types';
import type { RootState } from '../store';

export const initialState: AuthState = {
  status: Status.IDLE,
  tokens: undefined,
  initialized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearTokens: (state) => {
      state.tokens = undefined;
      localStorage.removeItem(KEY_TOKENS);
    },
    setTokens: (state, { payload }: PayloadAction<TokensModel>) => {
      state.tokens = payload;
    },
    setAuthInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.initialized = payload;
    },
    retrieveLocalStorageTokens: (state) => {
      if (state.initialized) return;

      const localStorageTokens = localStorage.getItem(KEY_TOKENS);
      if (!localStorageTokens) {
        state.initialized = true;
        return;
      }

      const tokens: OpenIdConnectTokenResponse = JSON.parse(localStorageTokens);

      if (!tokens.access_token) {
        state.initialized = true;
        state.tokens = undefined;
        return;
      }

      const decoded = decodeToken(tokens.access_token);
      if (!decoded.exp) {
        state.initialized = true;
        return;
      }

      if (isTokenExpired(decoded.exp)) {
        state.tokens = undefined;
        localStorage.removeItem(KEY_TOKENS);
      } else {
        state.tokens = {
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token,
          expiresIn: tokens.expires_in,
          tokenType: tokens.token_type,
        };

        startTimer(decoded.exp);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // authenticate
      .addCase(authenticate.pending, authenticateCaseHandlers.handlePending)
      .addCase(authenticate.fulfilled, authenticateCaseHandlers.handleFulfilled)
      .addCase(authenticate.rejected, authenticateCaseHandlers.handleRejected)

      // refreshAccessToken
      .addCase(refreshAccessToken.pending, refreshAccessTokenCaseHandlers.handlePending)
      .addCase(refreshAccessToken.fulfilled, refreshAccessTokenCaseHandlers.handleFulfilled)
      .addCase(refreshAccessToken.rejected, refreshAccessTokenCaseHandlers.handleRejected)

      // recoverPassword
      .addCase(sendRecoverPasswordEmail.pending, sendRecoverPasswordEmailCaseHandlers.handlePending)
      .addCase(sendRecoverPasswordEmail.fulfilled, sendRecoverPasswordEmailCaseHandlers.handleFulfilled)
      .addCase(sendRecoverPasswordEmail.rejected, sendRecoverPasswordEmailCaseHandlers.handleRejected)

      // resetPassword
      .addCase(resetPassword.pending, resetPasswordCaseHandlers.handlePending)
      .addCase(resetPassword.fulfilled, resetPasswordCaseHandlers.handleFulfilled)
      .addCase(resetPassword.rejected, resetPasswordCaseHandlers.handleRejected);
  },
});

export default authSlice.reducer;

export const {
  clearTokens, setTokens, setAuthInitialized, retrieveLocalStorageTokens,
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

import { reaction } from 'mobx';
import { detach, flow, types } from 'mobx-state-tree';
import parseContentItem from 'src/helpers/parseContentItem';
import { searchContents } from 'src/services/api/content';
import AuthStore from 'src/store/domain/Auth/AuthStore';
import ContentModel, { ContentModelSnapshot } from 'src/store/domain/ContentStore/ContentModel';
import { LearningTypes } from 'src/types/LearningTypes';
import { FlowReturn } from 'src/types/utils';

const LearningContentStore = types.model('LearningContentStore', {
  keywordSearch: '',
  currentPage: 1,
  pageSize: 4,
  pageCount: 5,
  totalResultCount: 0,
  loContentsFetched: false,

  LoContents: types.array(ContentModel),
  authStore: types.reference(AuthStore),
})

  .actions((self) => ({
    setKeywordsSearch(keywords: string) {
      self.keywordSearch = keywords;
    },
    setPageSize(pageSize: number) {
      self.pageSize = pageSize;
    },
    setPageCount(count: number) {
      self.pageCount = count;
    },
    setCurrentPage(page: number) {
      self.currentPage = page;
    },
    setLoContents(json: ContentModelSnapshot[]) {
      const contents = json.map((content) => ContentModel.create(content));
      detach(self.LoContents);

      self.LoContents.replace(contents);
    },
    resetLearningContentStore() {
      // Detach removes a model element from the state tree, and let it live on as a new state tree
      // This avoid issue with performance when we re-write something on the state
      detach(self.LoContents);
      self.keywordSearch = '';
      self.totalResultCount = 0;
      self.currentPage = 1;
      self.loContentsFetched = false;
    },
  }))

  .actions((self) => ({
    fetchLoContents: flow(function* fetchLoContents() {
      try {
        if (!self.authStore.tokens || !self.authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        const offset = (self.currentPage - 1) * self.pageSize;
        const organizationIdsStr = self.authStore.organizationIds.join(',');
        const queryParams = `?size=${self.pageSize}&offset=${offset}&organizationIds=${organizationIdsStr}`;

        type Response = FlowReturn<typeof searchContents>;
        const response: Response = yield searchContents(self.authStore.tokens.accessToken, {
          query: self.keywordSearch || '',
          types: [LearningTypes.TRAINING],

        }, queryParams);

        if (!response || !Array.isArray(response.items)) {
          return;
        }

        const parsedContent = response.items.map((item: any) => parseContentItem(item));

        self.setLoContents(parsedContent);
        self.totalResultCount = response.count || 0;
        self.pageCount = Math.ceil(self.totalResultCount / self.pageSize);
        self.loContentsFetched = true;
      } catch (e) {
        console.error('Unable to fetch the LoContents', e);
        self.loContentsFetched = false;
      }
    }),
  }))

  .actions((self) => ({
    afterCreate() {
      // Reactions to fetch the LO when the pagination or the keywordSearch change
      reaction(
        () => self.currentPage,
        () => {
          if (self.currentPage > 0) {
            self.fetchLoContents();
          }
        },
      );
      reaction(
        () => self.keywordSearch,
        () => {
          if (self.keywordSearch !== '') {
            self.fetchLoContents();
          }
        },
      );
    },
  }));

export default LearningContentStore;

export function createLearningContentStore(auth: string) {
  return {
    LoContents: [],
    authStore: auth,
  };
}

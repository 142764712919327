import { autorun, reaction } from 'mobx';
import { types } from 'mobx-state-tree';
import { ContentStore } from 'src/store/domain/ContentStore/ContentStore';
import { QueryStore } from 'src/store/domain/QueryStore/QueryStore';

import KeywordInput from './KeywordInput';

export const KeywordStore = types.model('KeywordStore', {
  keywords: '',

  headerInput: KeywordInput,
  libraryPageInput: KeywordInput,

  contentStore: types.reference(ContentStore),
  queryStore: types.reference(QueryStore),
})
  .actions((self) => ({

    setKeywords(keywords: string) {
      self.keywords = keywords;
    },

  }))
  .actions((self) => ({

    submitHeader() {
      self.keywords = self.headerInput.value;
      self.contentStore.setPage(1);
      self.contentStore.setKeywords(self.keywords);
      self.queryStore.setParam('page', '1');
      self.queryStore.setParam('keywords', self.keywords);
      self.queryStore.navigateWithQuery('/library');
      self.headerInput.setValue('');
    },

    submitLibrary() {
      self.keywords = self.libraryPageInput.value;
      self.contentStore.setPage(1);
      self.contentStore.setKeywords(self.keywords);
      self.queryStore.setParam('page', '1');
      self.queryStore.setParam('keywords', self.keywords);
      self.queryStore.navigateWithQuery('/library');
    },

  }))
  .actions((self) => ({

    afterCreate() {
      autorun(() => self.libraryPageInput.setValue(self.keywords));

      reaction(
        () => self.libraryPageInput.submitTrigger,
        () => self.submitLibrary(),
      );
      reaction(
        () => self.headerInput.submitTrigger,
        () => self.submitHeader(),
      );
    },

  }));

export function createKeywordStore(queryStore: string, contentStore: string) {
  return {
    headerInput: KeywordInput.create(),
    libraryPageInput: KeywordInput.create(),
    queryStore,
    contentStore,
  };
}

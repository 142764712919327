import { types } from 'mobx-state-tree';

export default types.model('UserModel', {
  id: types.maybe(types.number),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  email: types.maybe(types.string),
  avatarUrl: types.maybe(types.string),
  roles: types.array(types.string),
  activeRole: types.string,
});

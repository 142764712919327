import { useEffect } from 'react';

import { Error } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import useStore from 'src/store/useStore';

import './ErrorPage.scss';

export type ErrorPageProps = {
  message?: string,
  linkText?: string,
  linkPath?: string,
};

const ErrorPage = observer(({ message, linkText, linkPath }: ErrorPageProps) => {
  const store = useStore();
  const location = useLocation();
  const { t } = useTranslation('error');
  const theme = useTheme();
  const { grey } = theme.custom.palette;

  useEffect(() => {
    store.auth.setError(undefined);
  }, [store.auth.error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      className="ErrorPage"
      data-testid="ErrorPage"
    >
      <Error
        className="ErrorPage-icon"
        sx={{ color: grey[400] }}
      />

      <Typography className="ErrorPage-text">
        {
          location.state?.messageText
          || message
          || t('general')
        }
      </Typography>

      {location.state?.buttonText && location.state?.buttonUri && (
        <Link
          underline="none"
          className="ErrorPage-link"
          href={location.state.buttonUri}
        >
          {location.state.buttonText}
        </Link>
      )}

      {linkText && linkPath && (
        <Link
          underline="none"
          className="ErrorPage-link"
          component={RouterLink}
          to={linkPath}
        >
          {linkText}
        </Link>
      )}
    </Box>
  );
});
export default ErrorPage;

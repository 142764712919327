import { generateApiUrl, LO_PATH_PATTERNS, SEARCH } from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import {
  DetailedLearningObjectResponse,
  validateDetailedLearningObjectResponse,
} from 'src/types/validators/DetailedLearningObjectResponse';
import { SearchRequestBody } from 'src/types/validators/SearchRequestBody';
import {
  SearchResponseItemCollection,
  validateSearchResponseItemCollection,
} from 'src/types/validators/SearchResponseItemCollection';

import ConfigClient from '../config/ConfigClient';

export async function searchContents(accessToken: string, searchOptions: SearchRequestBody, searchUrlParams: string) {
  const route = ConfigClient.get('API_BASE_URL') + SEARCH.URL + searchUrlParams;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(searchOptions),
    headers,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected search fetch error', e);
    return;
  }

  let json: any;
  try {
    json = response.json();
  } catch (e) {
    console.error('Unable to serialize search result', e);
    return;
  }

  if (process.env.NODE_ENV === 'development' && !validateSearchResponseItemCollection(json)) {
    console.warn(
      'Search result structure is invalid. Probably api has changed.',
      getValidationErrors(),
      json,
    );
  }

  return json as SearchResponseItemCollection;
}

export async function getSingleLearningObject(accessToken: string, id: string) {
  const route = generateApiUrl(LO_PATH_PATTERNS.SINGLE_LO, { id });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'GET',
    headers,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected detailed object fetch error', e);
    return;
  }

  if (response.status !== 200) {
    console.error(`Search request failed with status ${response.status}`);
    return;
  }

  let json: any;
  try {
    json = response.json();
  } catch (e) {
    console.error('Unable to serialize learning object', e);
    return;
  }

  if (process.env.NODE_ENV === 'development' && !validateDetailedLearningObjectResponse(json)) {
    console.warn(
      'Detailed learning object result structure is invalid. Probably api has changed.',
      getValidationErrors(),
      json,
    );
  }

  return json as DetailedLearningObjectResponse;
}

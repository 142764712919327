import { types } from 'mobx-state-tree';

import categories from './sortCategories';
import { SortCategory, SortModel } from './SortModel';

export const SortStore = types.model('SortStore', {
  selectedSortOption: SortModel,
  categories: SortCategory,
})

  .actions((self) => ({

    selectOption(value: string) {
      const foundValue = self.categories.entries.find((entry) => entry.value === value);
      if (foundValue) {
        self.selectedSortOption = { ...foundValue };
      }
    },
  }))

  .views((self) => ({

    get selectedSortValue() {
      return self.selectedSortOption;
    },

  }));

export function createSortStore() {
  return SortStore.create({ categories, selectedSortOption: categories.entries[0] });
}

import {
  generateApiUrl, ENROLLMENTS_PATH_PATTERNS,
} from 'src/constants/api';
import { EnrollmentsItemResponse, EnrollmentsListResponse } from 'src/types/requests/Enrollments';

type CreateEnrollmentParams = {
  accessToken: string,
  userId: number,
  trainingProgramId: string,
  organizationId: number,
  type: string,
  dueAt: string,
};

export async function createEnrollment({
  accessToken,
  userId,
  trainingProgramId,
  organizationId,
  type,
  dueAt,
}: CreateEnrollmentParams) {
  const route = generateApiUrl(ENROLLMENTS_PATH_PATTERNS.ROOT);

  const body = {
    userId,
    trainingProgramId,
    organizationId,
    type,
    dueAt,
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  const response = await fetch(route, options);
  const json: EnrollmentsItemResponse = await response.json().catch(console.error);

  return json;
}

export async function deleteEnrollment(enrollmentId: string, accessToken: string) {
  const route = generateApiUrl(ENROLLMENTS_PATH_PATTERNS.ENROLLMENT, { enrollmentId });

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  return response.status;
}

export async function getEnrollment(enrollmentId: string) {
  const route = generateApiUrl(ENROLLMENTS_PATH_PATTERNS.ENROLLMENT, { enrollmentId });

  const accessToken = '';

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json: EnrollmentsItemResponse = await response.json().catch(console.error);

  return json;
}

export async function listEnrollments(
  accessToken: string,
  { userId, trainingProgramId }: { userId?: number, trainingProgramId?: string } = {},
) {
  const queryParams: {
    userId?: string,
    trainingProgramId?: string
  } = {};

  if (userId != null) {
    queryParams.userId = userId.toString();
  }

  if (trainingProgramId != null) {
    queryParams.trainingProgramId = trainingProgramId;
  }

  const route = generateApiUrl(ENROLLMENTS_PATH_PATTERNS.ROOT, {}, queryParams);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json: EnrollmentsListResponse = await response.json().catch(console.error);

  return json;
}

import { types, Instance } from 'mobx-state-tree';
import { NavigateFunction } from 'react-router-dom';

type KnownParams = 'sort' | 'keywords' | 'filters' | 'page';

/**
 * This store used to sync query parameters with search fields
 */
export const QueryStore = types.model('QueryStore', {
  id: types.identifier,

  queryString: '',
})
  .volatile(() => ({
    navigate: (() => {}) as NavigateFunction,
  }))
  .actions((self) => ({
    setNavigateFunction(fn: NavigateFunction) {
      self.navigate = fn;
    },

    navigateWithQuery(pathname: string) {
      self.navigate({
        pathname,
        search: self.queryString,
      });
    },

    setQuery(query: string) {
      self.queryString = query;
    },

    setParam(name: KnownParams, value: string) {
      const newUrl = new URLSearchParams(self.queryString);
      if (value) {
        newUrl.set(name, value);
      } else {
        newUrl.delete(name);
      }
      self.queryString = newUrl.toString();
    },

  }))
  .views((self) => ({

    get sort() {
      return new URLSearchParams(self.queryString).get('sort') || '';
    },

    get filters() {
      return new URLSearchParams(self.queryString).get('filters') || '';
    },

    get keywords() {
      return new URLSearchParams(self.queryString).get('keywords') || '';
    },

    get page() {
      return parseInt(new URLSearchParams(self.queryString).get('page') || '1', 10) || 1;
    },

  }));

export function createQueryStore() {
  return {
    id: QueryStore.name,
  };
}

export interface QueryStoreInstance extends Instance<typeof QueryStore> {}

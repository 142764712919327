import { LearningTypes } from 'src/types/LearningTypes';
import { TrainingProgramResponse, TrainingProgramStep } from 'src/types/validators/TrainingProgramResponse';
import { TrainingProgramStepResponse } from 'src/types/validators/TrainingProgramStepResponse';

import TrainingProgramModel, { TrainingProgramStepSnapshot } from './TrainingProgramModel';

export default function deserializeProgram(json: TrainingProgramResponse) {
  const stepsReducer = (
    acc: Record<string, TrainingProgramStepSnapshot>,
    curr: TrainingProgramStep,
    idx: number,
  ): Record<string, TrainingProgramStepSnapshot> => {
    if (!curr.id) {
      throw new Error(`No id for step '${curr.title}'`);
    }
    acc[curr.id] = deserializeStep(curr, idx, false);
    return acc;
  };

  const stepsMap = json.steps?.reduce(
    stepsReducer,
    {} as Record<string, TrainingProgramStepSnapshot>,
  ) || {} as Record<string, TrainingProgramStepSnapshot>;
  return TrainingProgramModel.create({
    id: json.id,
    title: json.title,
    description: json.description || null,
    languageTag: json.languageTag,
    estimatedDuration: json.estimatedDuration,
    referenceCode: json.referenceCode,
    thumbnailPath: json.thumbnailPath,
    tags: json.tags,
    // TODO: API have some issues with this field, uncoment when they fix
    // authors: json.authors,
    authors: ['John Doe'],
    organizationIds: json.organizationIds,
    productIds: json.productIds,
    isDiscoverable: json.isDiscoverable,
    createdAt: new Date(json.createdAt),
    updatedAt: new Date(json.updatedAt),
    stepsMap,
  });
}

export function deserializeStep(
  json: TrainingProgramStep | TrainingProgramStepResponse,
  order: number,
  // param to deserialize LOs or not
  withLOs: boolean,
): TrainingProgramStepSnapshot {
  if (withLOs) {
    const stepData = json as TrainingProgramStepResponse;

    return {
      id: stepData.id as string,
      title: stepData.title as string,
      description: stepData.description || '',
      estimatedDuration: stepData.estimatedDuration || null,
      createdAt: stepData.createdAt ? new Date(stepData.createdAt) : 0,
      updatedAt: stepData.updatedAt ? new Date(stepData.updatedAt) : 0,
      learningObjects: stepData.learningObjects?.map((item) => ({
        isMandatory: item.isMandatory,
        learningObject: {
          id: item.learningObject.id || '',
          title: item.learningObject.title,
          description: item.learningObject.description || null,
          estimatedDuration: item.learningObject.estimatedDuration,
          thumbnailPath: item.learningObject.thumbnailPath,
          type: LearningTypes[item.learningObject.type],
        },
      })) || [],
      order,
    };
  }

  return {
    id: json.id as string,
    title: json.title as string,
    description: json.description || '',
    estimatedDuration: json.estimatedDuration || null,
    createdAt: json.createdAt ? new Date(json.createdAt) : 0,
    updatedAt: json.updatedAt ? new Date(json.updatedAt) : 0,
    learningObjects: [],
    order,
  };
}

import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getTrainingProgramList } from 'src/services/requests/trainingProgramList';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrainingProgramResponse } from 'src/types/requests/TrainingPrograms';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { TrainingProgramListState } from './types';
import type { RootState } from '../store';

export const fetchTrainingProgramList = createAsyncThunk(
  'trainingProgramList/fetchTrainingProgramList',
  async (_params, { rejectWithValue, getState }) => {
    const { auth, user, trainingProgramList } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('Training program list fetch: User is not logged in');
    }

    const offset = (trainingProgramList.currentPage - 1) * trainingProgramList.pageSize;
    const organizationIdsStr = user.organizationIds.join(',');

    const queryParams = { size: trainingProgramList.pageSize, offset, organizationIds: organizationIdsStr };

    type Response = FlowReturn<typeof getTrainingProgramList>;
    const response: Response = await getTrainingProgramList(
      auth.tokens.accessToken,
      queryParams,
    );

    if (!response) {
      return rejectWithValue('Training program list fetch: Could not fetch program list');
    }

    const { items, count } = response;
    const totalResultCount = count || 0;
    const pageCount = Math.ceil(totalResultCount / trainingProgramList.pageSize);

    return { items, totalResultCount, pageCount };
  },
);

export const fetchTrainingProgramListCaseHandlers: ThunkCaseHandlers<TrainingProgramListState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.trainingPrograms = [];
  },
  handleFulfilled: (state, { payload }: PayloadAction<{
    items: TrainingProgramResponse[],
    totalResultCount: number,
    pageCount: number,
  }>) => {
    state.trainingPrograms = payload.items;
    state.totalResultCount = payload.totalResultCount;
    state.pageCount = payload.pageCount;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }: PayloadAction<string>) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

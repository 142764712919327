import React from 'react';

import ReactDOM from 'react-dom/client';

import '@fontsource/open-sans';
import './config/i18n';
import App from './App';
import config from './services/config/config';
import ConfigClient from './services/config/ConfigClient';

ConfigClient.init(config);
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { LoggedUser } from 'src/types/LoggedUser';

import ActiveRoleMenuItem from './ActiveRoleMenuItem/ActiveRoleMenuItem';
import EmailMenuItem from './EmailMenuItem/EmailMenuItem';
import LogOutMenuItem from './LogOutMenuItem/LogOutMenuItem';
// import MyAccountMenuItem from './MyAccountMenuItem/MyAccountMenuItem';
// import SettingsMenuItem from './SettingsMenuItem/SettingsMenuItem';
import SwitchExperienceMenuItem from './SwitchExperienceMenuItem/SwitchExperienceMenuItem';
import SwitchRoleMenuItem from './SwitchRoleMenuItem/SwitchRoleMenuItem';
import UserNameMenuItem from './UserNameMenuItem/UserNameMenuItem';

interface MainHeaderProfileMenuProps {
  menuId: string,
  anchorEl: HTMLElement | null,
  handleMenuClose: () => void,
  loggedUser: LoggedUser,
  logoutUser: () => void,
  fetchOriginalExperienceUri: () => Promise<string | undefined>,
  switchActiveRole: (role: string) => void,
  setExperienceLoading: (isLoading: boolean) => void
}

function MainHeaderProfileMenu({
  menuId, anchorEl, handleMenuClose, loggedUser, logoutUser, fetchOriginalExperienceUri, switchActiveRole, setExperienceLoading,
}: MainHeaderProfileMenuProps) {
  const isMenuOpen = Boolean(anchorEl);
  const { t } = useTranslation();

  const username = useMemo(
    () => [loggedUser.firstName, loggedUser.lastName].filter(Boolean).join(' '),
    [loggedUser.firstName, loggedUser.lastName],
  );

  const keyActiveRole = useMemo(() => loggedUser.activeRole.replace(/-./g, (x) => x[1].toUpperCase()), [loggedUser.activeRole]);

  const enableSwitchRoleMenu = useMemo(() => loggedUser.roles.length > 1, [loggedUser.roles]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      aria-label="user menu"
    >
      {username && <UserNameMenuItem text={username} />}

      {loggedUser.email && <EmailMenuItem text={loggedUser.email} /> }

      <ActiveRoleMenuItem text={t(`profileMenu.${keyActiveRole}`)} />

      <Divider sx={{ mb: 1 }} />

      {/* <MyAccountMenuItem text={t('profileMenu.myAccount')} /> */}

      {/* <SettingsMenuItem text={t('profileMenu.settings')} /> */}

      {enableSwitchRoleMenu && (
      <SwitchRoleMenuItem
        switchActiveRole={switchActiveRole}
        text={t('profileMenu.switchRole')}
        availableRoles={loggedUser.roles}
      />
      )}

      <LogOutMenuItem
        logoutUser={logoutUser}
      />

      <Divider sx={{ mb: 1 }} />

      <SwitchExperienceMenuItem
        text={t('profileMenu.switchExperience')}
        fetchOriginalExperienceUri={fetchOriginalExperienceUri}
        onSwitch={handleMenuClose}
        setExperienceLoading={setExperienceLoading}
      />
    </Menu>
  );
}

export default MainHeaderProfileMenu;

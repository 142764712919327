import {
  DELETE_LEARNING_OBJECT,
  DELETE_PROGRAM_STEP,
  GET_PROGRAM_STEP,
  POST_LEARNING_OBJECT,
  POST_PROGRAM_STEP,
  PUT_PROGRAM_STEP,
} from 'src/constants/api';
import ConfigClient from 'src/services/config/ConfigClient';
import { getValidationErrors } from 'src/types/validators/ajv';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import { TrainingProgramLearningObjectResponse } from 'src/types/validators/TrainingProgramLearningObjectResponse';
import {
  TrainingProgramStepRequest,
} from 'src/types/validators/TrainingProgramStepRequest';
import {
  TrainingProgramStepResponse,
  validateTrainingProgramStepResponse,
} from 'src/types/validators/TrainingProgramStepResponse';

export async function postTrainingProgramStep(
  stepData: TrainingProgramStepRequest,
  programId: string,
  accessToken: string,
): Promise<TrainingProgramStepResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL') + POST_PROGRAM_STEP.URL.replace(POST_PROGRAM_STEP.KEY_PROGRAM_ID, programId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(stepData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateTrainingProgramStepResponse(json)) {
    return json;
  }

  console.error('Step post response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

export async function deleteTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
): Promise<boolean> {
  const route = ConfigClient.get('API_BASE_URL')
  + DELETE_PROGRAM_STEP.URL.replace(DELETE_PROGRAM_STEP.KEY_PROGRAM_ID, programId)
    .replace(DELETE_PROGRAM_STEP.KEY_PROGRAM_STEP_ID, stepId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    console.error(`Step delete request failed with status ${response.status}`, response);

    return false;
  }

  if (response) {
    return true;
  }

  console.error('Step delete response structure is invalid. Probably API has changed', getValidationErrors(), response);
  return false;
}

export async function putTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
  stepData: TrainingProgramStepRequest,
): Promise<TrainingProgramStepResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL')
  + PUT_PROGRAM_STEP.URL.replace(PUT_PROGRAM_STEP.KEY_PROGRAM_ID, programId)
    .replace(PUT_PROGRAM_STEP.KEY_PROGRAM_STEP_ID, stepId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const body = {
    title: stepData.title,
    description: stepData.description,
    learningObjects: stepData.learningObjectIds || [],
  };

  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step put request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step put request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step put request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateTrainingProgramStepResponse(json)) {
    return json;
  }

  console.error('Step put response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

export async function getTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
): Promise<TrainingProgramStepResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL')
  + GET_PROGRAM_STEP.URL
    .replace(GET_PROGRAM_STEP.KEY_PROGRAM_ID, programId)
    .replace(GET_PROGRAM_STEP.KEY_PROGRAM_STEP_ID, stepId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function postLearningObjectToStep(
  programId: string,
  stepId: string,
  learningObjectId: string,
  accessToken: string,
): Promise<TrainingProgramLearningObjectResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL') + POST_LEARNING_OBJECT.URL
    .replace(POST_LEARNING_OBJECT.KEY_PROGRAM_ID, programId)
    .replace(POST_LEARNING_OBJECT.KEY_PROGRAM_STEP_ID, stepId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const body = {
    learningObject: {
      id: learningObjectId,
    },
    isMandatory: true,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`LO to Step post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`LO to Step post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`LO to Step post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function deleteLearningObjectFromStep(
  programId: string,
  stepId: string,
  learningObjectId: string,
  accessToken: string,
): Promise<boolean> {
  const route = ConfigClient.get('API_BASE_URL') + DELETE_LEARNING_OBJECT.URL
    .replace(DELETE_LEARNING_OBJECT.KEY_PROGRAM_ID, programId)
    .replace(DELETE_LEARNING_OBJECT.KEY_PROGRAM_STEP_ID, stepId)
    .replace(DELETE_LEARNING_OBJECT.KEY_LEARNING_OBJECT_ID, learningObjectId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    console.error(`LO of Step delete request failed with status ${response.status}`, response);

    return false;
  }

  if (response) {
    return true;
  }

  console.error('LO of Step delete response structure is invalid. Probably API has changed', getValidationErrors(), response);
  return false;
}

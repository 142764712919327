export default
{
  name: 'sorting.buttonLabel',
  entries: [
    { name: 'sorting.options.relevant', id: '1', value: 'RELEVANCE' },
    { name: 'sorting.options.recent', id: '2', value: 'LAST_MODIFICATION_DATE_ASC' },
    { name: 'sorting.options.alphabetAsc', id: '3', value: 'TITLE_ASC' },
    { name: 'sorting.options.alphabetDesc', id: '4', value: 'TITLE_DESC' },
  ],
};

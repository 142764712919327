import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrainingProgramResponse } from 'src/types/requests/TrainingPrograms';
import { Status } from 'src/types/Status';
import ProgramData from 'src/types/TrainingProgram';
import { FlowReturn } from 'src/types/utils';
import { TrainingProgramRequest } from 'src/types/validators/TrainingProgramRequest';

import { postTrainingProgram, putTrainingProgram, getProgram } from './api';
import { TrainingProgramState } from './types';
import type { RootState } from '../store';

/**
 * Fetch the program by id and set it to current program
 */
export const fetchProgram = createAsyncThunk(
  'trainingProgram/fetchProgram',
  async (programId: string, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    type TrainingProgramResponse = FlowReturn<typeof getProgram>;
    try {
      const response: TrainingProgramResponse = await getProgram(programId, auth.tokens.accessToken);
      if (!response) {
        return rejectWithValue('Failed to get Training Program');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const fetchProgramCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramResponse>) => {
    state.status = Status.SUCCEEDED;
    state.currentProgram = payload;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

export const createProgram = createAsyncThunk(
  'trainingProgram/createProgram',
  async (programData: ProgramData, { rejectWithValue, getState }) => {
    const { user, auth } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    programData.languageTag = 'en-US';
    if (user.organizationIds.length) {
      programData.organizationIds = user.organizationIds;
    }
    const programBody = programData as TrainingProgramRequest;
    try {
      type TrainingProgramResponse = FlowReturn<typeof postTrainingProgram>;
      const response: TrainingProgramResponse = await postTrainingProgram(programBody, auth.tokens.accessToken);
      if (!response) {
        return rejectWithValue('Program is not created');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const createProgramCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramResponse>) => {
    state.status = Status.SUCCEEDED;
    state.currentProgram = payload;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

export const updateProgram = createAsyncThunk(
  'trainingProgram/updateProgram',
  async (programData: ProgramData, { getState, rejectWithValue }) => {
    const { auth, trainingProgram } = getState() as RootState;
    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('User is not logged in');
    }
    const { currentProgram } = trainingProgram;
    if (!currentProgram || !currentProgram.id || !currentProgram.title || !currentProgram.languageTag) {
      return rejectWithValue('Current program is empty');
    }
    programData.title = programData.title || currentProgram.title;
    programData.languageTag = programData.languageTag || currentProgram.languageTag;
    programData.thumbnailPath = programData.thumbnailPath || currentProgram.thumbnailPath;
    const programBody = programData as TrainingProgramRequest;
    type TrainingProgramResponse = FlowReturn<typeof putTrainingProgram>;
    try {
      const response: TrainingProgramResponse = await putTrainingProgram(
        currentProgram.id,
        programBody,
        auth.tokens.accessToken,
      );
      if (!response) {
        return rejectWithValue('Program is not created');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const updateProgramCaseHandlers: ThunkCaseHandlers<TrainingProgramState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramResponse>) => {
    state.status = Status.SUCCEEDED;
    state.currentProgram = payload;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
    state.showApiErrDialog = true;
  },
};

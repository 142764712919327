import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AssignmentModalMode, EnrollmentsState, Learner } from 'src/types/Enrollments';
import { Status } from 'src/types/Status';

import {
  fetchAssignedLearners,
  deleteAssignedLearner,
  enrollLearners,
  fetchAssignedLearnersCaseHandlers,
  deleteAssignedLearnerCaseHandlers,
  enrollLearnersCaseHandlers,
} from './thunks';
import type { RootState } from '../store';

export const initialState: EnrollmentsState = {
  status: Status.IDLE,
  modalOpen: false,
  modalMode: AssignmentModalMode.ASSIGN_LEARNERS,
  modalLoading: false,
  learnersEnrolled: [],
  assignedLearners: [],
  trainingProgramId: undefined,
};

const enrollmentsSlice = createSlice({
  name: 'enrollments',
  initialState,
  reducers: {
    openModal(state) {
      state.modalOpen = true;
    },
    closeModal(state) {
      state.modalOpen = false;
      state.learnersEnrolled = [];
      state.assignedLearners = [];
      state.trainingProgramId = undefined;
    },
    setModalMode(state, { payload }: PayloadAction<AssignmentModalMode>) {
      state.modalMode = payload;
    },
    setLearnersEnrolled(state, { payload }: PayloadAction<Learner[]>) {
      state.learnersEnrolled = payload;
    },
    setTrainingProgramId(state, { payload }: PayloadAction<string>) {
      state.trainingProgramId = payload;
    },
    resetEnrollmentsState(state) {
      state.learnersEnrolled = [];
      state.assignedLearners = [];
      state.trainingProgramId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchAssignedLearners
      .addCase(fetchAssignedLearners.pending, fetchAssignedLearnersCaseHandlers.handlePending)
      .addCase(fetchAssignedLearners.fulfilled, fetchAssignedLearnersCaseHandlers.handleFulfilled)
      .addCase(fetchAssignedLearners.rejected, fetchAssignedLearnersCaseHandlers.handleRejected)

      // deleteAssignedLearners
      .addCase(deleteAssignedLearner.pending, deleteAssignedLearnerCaseHandlers.handlePending)
      .addCase(deleteAssignedLearner.fulfilled, deleteAssignedLearnerCaseHandlers.handleFulfilled)
      .addCase(deleteAssignedLearner.rejected, deleteAssignedLearnerCaseHandlers.handleRejected)

      // enrollLearners
      .addCase(enrollLearners.pending, enrollLearnersCaseHandlers.handlePending)
      .addCase(enrollLearners.fulfilled, enrollLearnersCaseHandlers.handleFulfilled)
      .addCase(enrollLearners.rejected, enrollLearnersCaseHandlers.handleRejected);
  },
});

export default enrollmentsSlice.reducer;

export const {
  openModal, closeModal, setModalMode, setLearnersEnrolled, setTrainingProgramId, resetEnrollmentsState,
} = enrollmentsSlice.actions;

export const selectEnrollments = (state: RootState) => state.enrollments;

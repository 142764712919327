import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ObjectOfStrings } from 'src/constants/api';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { Status } from 'src/types/Status';
import { EventListResponse } from 'src/types/validators/EventListResponse';

import { getEvents } from './api';
import { EventListState } from './types';

interface FetchEventsParams {
  accessToken: string,
  organizerId: number,
  dateQuery: string,
  size?: string,
}

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async ({
    accessToken,
    organizerId,
    dateQuery,
    size = '20',
  }: FetchEventsParams, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue('User is not logged in');
    }

    const eventQueryParams: ObjectOfStrings = {
      organizerId: organizerId.toString(),
      startAtGreaterThanEqual: encodeURIComponent(`${dateQuery}T00:00:00+00:00`),
      startAtLowerThanEqual: encodeURIComponent(`${dateQuery}T23:59:00+00:00`),
      size,
    };

    try {
      const response = await getEvents(accessToken, eventQueryParams);
      if (!response) {
        return rejectWithValue('Failed to fetch events');
      }

      return response;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const fetchEventsCaseHandlers: ThunkCaseHandlers<EventListState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EventListResponse>) => {
    if (payload.items?.length) {
      state.events = payload.items;
    }
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

import { useState } from 'react';

// import MenuIcon from '@mui/icons-material/Menu';
import Search from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
// import { ROUTES, reverseRoute } from 'src/config/routes/routes';
import useStore from 'src/store/useStore';
import { LoggedUser } from 'src/types/LoggedUser';
import { NavOption } from 'src/types/NavOption';

import MainHeaderMobileMenu from './MainHeaderMobileMenu/MainHeaderMobileMenu';
// import MainHeaderNavigation from './MainHeaderNavigation/MainHeaderNavigation';
import MainHeaderProfileIcon from './MainHeaderProfileIcon/MainHeaderProfileIcon';
import MainHeaderProfileMenu from './MainHeaderProfileMenu/MainHeaderProfileMenu';
import MainHeaderSearch from './MainHeaderSearch/MainHeaderSearch';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';

const navOptions: NavOption[] = [
  // {
  //   path: reverseRoute(ROUTES.home),
  //   translationKey: 'nav.home',
  // },
  // {
  //   path: reverseRoute(ROUTES.library),
  //   translationKey: 'nav.library',
  //   activateTab: [reverseRoute(ROUTES.learningDetail)],
  // },
];

// eslint-disable-next-line max-len
const logo = 'https://www.wiley.com/medias/Wiley-Logo-Black.jpg?context=bWFzdGVyfGltYWdlc3wxMTc0NnxpbWFnZS9qcGVnfHN5cy1tYXN0ZXIvaW1hZ2VzL2g5OS9oYjIvODg4MzY3ODk2OTg4Ni9XaWxleSBMb2dvIEJsYWNrLmpwZ3w3ZDIwZGE3N2M0OTdhOTgwMWIwZjNlODhkN2RlY2JmN2Q5NGQyNDdhZWE4NTZmOGZlODkxZjYxYmE0NjM0ZGM5';

interface MainHeaderProps {
  loggedUser?: LoggedUser,
  logoutUser: () => void,
  fetchOriginalExperienceUri: () => Promise<string | undefined>,
  switchActiveRole: (roleName: string) => void,
  setExperienceLoading: (isLoading: boolean) => void,
}

function MainHeader({
  loggedUser, logoutUser, fetchOriginalExperienceUri, switchActiveRole, setExperienceLoading,
}: MainHeaderProps) {
  const theme = useTheme();

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<null | HTMLElement>(null);

  const profileMenuId = 'profile-menu';
  const mobileMenuId = 'mobile-menu';
  // const notificationsMenuId = 'notification-menu';

  const handleProfileMenuOpen = (event: React.ChangeEvent<any>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event: React.ChangeEvent<any>) => {
  //   setMobileMenuAnchorEl(event.currentTarget);
  // };

  const handleSwitchActiveRole = (roleName: string) => {
    switchActiveRole(roleName);
    handleProfileMenuClose();
  };

  const store = useStore();

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: theme.palette.secondary.dark,
          boxShadow: 'none',
        }}
      >
        <MaxWidthContainer>

          <Collapse
            in={showMobileSearch}
            collapsedSize={theme.spacing(8)}
          >
            <Toolbar
              disableGutters
              sx={{ flexFlow: { xs: 'wrap', sm: 'unset' } }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: { xs: 1, md: 0 },
                }}
              >
                {/* Display only when user is authenticated */
                // loggedUser && (
                //   <IconButton
                //     size="large"
                //     edge="start"
                //     color="inherit"
                //     aria-label="menu"
                //     sx={{
                //       display: { xs: 'block', md: 'none' },
                //       mr: 2,
                //       lineHeight: 0,
                //     }}
                //     onClick={handleMobileMenuOpen}
                //   >
                //     <MenuIcon />
                //   </IconButton>
                // )
              }

                {logo && (
                <img
                  style={{ maxHeight: '60px', maxWidth: '150px' }}
                  height={60}
                  width={136}
                  alt="Client Logo"
                  src={logo}
                />
                )}
              </Box>

              {/* Display only when user is authenticated */
              loggedUser && (
                <>
                  <Box sx={{
                    /**
                     * we use visibility and width instead of display to avoid
                     * a console error from the tabs component
                     * https://stackoverflow.com/questions/69024833/hide-component-that-renders-a-tabs
                     */
                    visibility: { xs: 'hidden', md: 'visible' },
                    width: { xs: '0', md: '100%' },
                    flexGrow: 1,
                    mt: theme.spacing(2),
                  }}
                  >
                    {/* <MainHeaderNavigation navOptions={navOptions} /> */}
                  </Box>

                  <Box sx={{ display: { xs: 'none', sm: 'none' } }}>
                    <MainHeaderSearch input={store.libraryPage.keywordsStore.headerInput} />
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <IconButton
                      size="large"
                      aria-label="Show Search Input"
                      color="inherit"
                      sx={{ display: { xs: 'flex', sm: 'none' } }}
                      onClick={() => setShowMobileSearch(!showMobileSearch)}
                    >
                      <Search />
                    </IconButton>

                    <MainHeaderProfileIcon
                      profileMenuId={profileMenuId}
                      handleProfileMenuOpen={handleProfileMenuOpen}
                      loggedUser={loggedUser}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'none' },
                      width: '100%',
                    }}
                  >
                    <MainHeaderSearch
                      disabled={!showMobileSearch}
                      input={store.libraryPage.keywordsStore.headerInput}
                    />
                  </Box>
                </>
              )
            }
            </Toolbar>
          </Collapse>
        </MaxWidthContainer>

        <Box
          sx={{
            height: theme.spacing(0.5),
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
          }}
        />
      </AppBar>

      {/* Display only when user is authenticated */
        loggedUser && (
          <>
            <MainHeaderMobileMenu
              menuId={mobileMenuId}
              anchorEl={mobileMenuAnchorEl}
              handleMenuClose={handleMobileMenuClose}
              navOptions={navOptions}
            />

            <MainHeaderProfileMenu
              menuId={profileMenuId}
              anchorEl={profileMenuAnchorEl}
              handleMenuClose={handleProfileMenuClose}
              loggedUser={loggedUser}
              logoutUser={logoutUser}
              fetchOriginalExperienceUri={fetchOriginalExperienceUri}
              switchActiveRole={handleSwitchActiveRole}
              setExperienceLoading={setExperienceLoading}
            />
          </>
        )
      }
    </>
  );
}

export default MainHeader;

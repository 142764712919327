import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './auth/slice';
import enrollmentsReducer from './enrollments/slice';
import eventListReducer from './eventList/slice';
import imageReducer from './image/slice';
import learningObjectsReducer from './learningObjects/slice';
import reportsReducer from './reports/slice';
import trainingProgramReducer from './trainingProgram/slice';
import trainingProgramListReducer from './trainingProgramList/slice';
import userReducer from './user/slice';

export const storeReducer = {
  auth: authReducer,
  user: userReducer,
  trainingProgram: trainingProgramReducer,
  trainingProgramList: trainingProgramListReducer,
  learningObjects: learningObjectsReducer,
  images: imageReducer,
  eventList: eventListReducer,
  enrollments: enrollmentsReducer,
  reports: reportsReducer,
};

export const store = configureStore({
  reducer: storeReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LearningObjectModel, LearningTypes } from 'src/types/LearningTypes';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { searchContents } from './api';
import { LearningObjectsState } from './types';
import type { RootState } from '../store';

export const searchLearningObjects = createAsyncThunk(
  'learningObjects/search',
  async (
    keyword: string,
    { rejectWithValue, getState },
  ) => {
    const { auth, user, learningObjects } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('Learning objects search: User is not logged in');
    }

    const offset = (learningObjects.currentPage - 1) * learningObjects.pageSize;
    const organizationIdsStr = user.organizationIds.join(',');
    const queryParams = `
      ?size=${learningObjects.pageSize}
      &offset=${offset}
      &organizationIds=${organizationIdsStr}
    `;

    type Response = FlowReturn<typeof searchContents>;
    const response: Response = await searchContents(auth.tokens.accessToken, {
      query: keyword || '',
      types: [LearningTypes.TRAINING],
    }, queryParams);

    if (!response || !Array.isArray(response.items)) {
      return rejectWithValue('Learning objects search: Failed trying to search for learning objects');
    }

    const { items, count } = response;
    const totalResultCount = count || 0;
    const pageCount = Math.ceil(totalResultCount / learningObjects.pageSize);

    return { items, totalResultCount, pageCount };
  },
);

export const searchCaseHandlers: ThunkCaseHandlers<LearningObjectsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.learningObjects = [];
  },
  handleFulfilled: (state, action: PayloadAction<{
    items: LearningObjectModel[],
    totalResultCount: number,
    pageCount: number,
  }>) => {
    state.learningObjects = action.payload.items;
    state.totalResultCount = action.payload.totalResultCount;
    state.pageCount = action.payload.pageCount;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

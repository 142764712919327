import { types, SnapshotIn, Instance } from 'mobx-state-tree';
import { LearningTypes } from 'src/types/LearningTypes';

const ProgramLearningObjectModel = types.model('ProgramLearningObjectModel', {
  id: types.identifier,
  title: types.string,
  description: types.maybeNull(types.string),
  estimatedDuration: types.maybeNull(types.number),
  thumbnailPath: types.maybeNull(types.string),
  type: types.enumeration(Object.values(LearningTypes)),
});

const LearningObjectMandatoryField = types.model('LearningObjectMandatoryField', {
  isMandatory: types.boolean,
  learningObject: ProgramLearningObjectModel,
});

const TrainingProgramStepModel = types.model('TrainingProgramStepModel', {
  id: types.identifier,
  title: types.string,
  description: types.maybe(types.string),
  estimatedDuration: types.maybeNull(types.number),
  createdAt: types.Date,
  updatedAt: types.Date,
  learningObjects: types.array(LearningObjectMandatoryField),
  order: types.number, // Necessary because we use a map in the TrainingProgramModel
});

const TrainingProgramModel = types.model('TrainingProgramModel', {
  id: types.identifier,
  title: types.string,
  description: types.maybeNull(types.string),
  estimatedDuration: types.maybeNull(types.number),
  languageTag: types.string,
  referenceCode: types.maybeNull(types.string),
  thumbnailPath: types.maybeNull(types.string),
  tags: types.array(types.string),
  authors: types.array(types.string),
  organizationIds: types.array(types.number),
  productIds: types.array(types.string),
  isDiscoverable: types.boolean,
  // Using a map instead of an array allows for easier access when updating steps. 
  // It's also the best way i found to make reactivity functions correctly with it, 
  // I couldn't deal with it when using an array;
  // Shouldn't be really used outside of the store itself, `
  // since there is the steps views to get an array view of the steps. 
  stepsMap: types.map(TrainingProgramStepModel),
  createdAt: types.Date,
  updatedAt: types.Date,
}).views((self) => ({
  get steps() {
    return Array.from(self.stepsMap.values()).sort((first, second) => first.order - second.order);
  },
  get learningObjectsCount() {
    let count = 0;
    const stepsArray = Array.from(self.stepsMap.values());
    for (const step of stepsArray) {
      count += step.learningObjects.length;
    }
    return count;
  },
}));

export type TrainingProgramModelInstance = Instance<typeof TrainingProgramModel>;

export type TrainingProgramModelSnapshot = SnapshotIn<typeof TrainingProgramModel>;
export default TrainingProgramModel;
export type TrainingProgramStepSnapshot = SnapshotIn<typeof TrainingProgramStepModel>;
export type TrainingProgramLearningObjectSnapshot = SnapshotIn<typeof ProgramLearningObjectModel>;
export type LearningObjectMandatoryFieldSnapshot = SnapshotIn<typeof LearningObjectMandatoryField>;

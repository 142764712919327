import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { getImage, postImage } from './api';
import { CreateImageParams, ImageState } from './types';
import type { RootState } from '../store';

// Create a new image
export const createImage = createAsyncThunk(
  'image/createImage',
  async ({ imageData, imageType }: CreateImageParams, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens) return rejectWithValue('Missing tokens.');

    const { accessToken } = auth.tokens;
    if (!accessToken) return rejectWithValue('Not found access token.');
    if (!imageData || !imageType) return rejectWithValue('Image not provided.');

    type CreateImageResponse = FlowReturn<typeof postImage>;
    const response: CreateImageResponse = await postImage(imageType, imageData, accessToken);

    if (!response) return rejectWithValue('Image was not uploaded');

    return response;
  },
);

export const createImageCaseHandlers: ThunkCaseHandlers<ImageState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    console.error(action.payload);
    state.status = Status.FAILED;
  },
};

// Fetch an existent image
export const fetchImageUrl = createAsyncThunk(
  'image/fetchImageUrl',
  async (fileName: string, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens) return rejectWithValue('fetchImageUrl: Missing tokens.');

    const { accessToken } = auth.tokens;
    if (!accessToken) return rejectWithValue('fetchImageUrl: Not found access token.');

    type FetchImageResponse = FlowReturn<typeof getImage>;
    const response: FetchImageResponse = await getImage(fileName, accessToken);

    if (!response) return rejectWithValue('fetchImageUrl: Image was not found');

    return response.url;
  },
);

export const fetchImageUrlCaseHandlers: ThunkCaseHandlers<ImageState> = {
  handleFulfilled: () => {},
  handlePending: () => {},
  handleRejected: (state, action) => {
    console.error(action.payload);
  },
};

/**
 * ColorChange component is temporary functionality and should be removed in the future
 */
import { types } from 'mobx-state-tree';
import { defaultPrimaryColor, defaultSecondaryColor } from 'src/config/theme/theme';

/**
 * This store manages ColorChange component and validates it's values
 */
export default types.model('ColorChangeStore', {
  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
}).views((self) => ({
  get validatedPrimaryColor() {
    return isValidHexColor(self.primaryColor) ? self.primaryColor : defaultPrimaryColor;
  },
  get validatedSecondaryColor() {
    return isValidHexColor(self.secondaryColor) ? self.secondaryColor : defaultSecondaryColor;
  },
})).actions((self) => ({
  setPrimaryColor(color: string) {
    self.primaryColor = color;
  },
  setSecondaryColor(color: string) {
    self.secondaryColor = color;
  },
}));

function isValidHexColor(color: string) {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}

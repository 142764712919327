import { FormEvent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TRACK_SEARCH_FROM_HEADER } from 'src/constants/measurements';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';

const Search = styled('form')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface MainHeaderSearchProps {
  disabled?: boolean,
  input: {
    value: string,
    setValue: (value: string) => void,
    submit: () => void,
  }
}

const MainHeaderSearch = observer(({
  disabled = false,
  input: {
    value,
    setValue,
    submit,
  },
}: MainHeaderSearchProps) => {
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value) {
      MeasurementsClient.track(TRACK_SEARCH_FROM_HEADER, {
        value,
      });
      submit();
    }
  };

  return (
    <Search
      onSubmit={handleSubmit}
      role="search"
    >
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>

      <StyledInputBase
        placeholder={t('nav.search')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        disabled={disabled}
      />
    </Search>
  );
});

export default MainHeaderSearch;

import React from 'react';

import { useLocalObservable } from 'mobx-react-lite';

import { createRootStore, Store } from './RootStore';

export const RootContext = React.createContext<Store | undefined>(undefined);

/**
 * Allows to useStore inside nested components
 */
export function StoreProvider({ children }: any) {
  const store = useLocalObservable(createRootStore);
  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
}

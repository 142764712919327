let manualResolver: ManagedPromise<boolean>;
let timeoutId: NodeJS.Timeout | undefined;

/**
 * Start timer
 * Returns promise, which will resolve when timer ends,
 * or when stopTimer function is called
 * @returns true if timer finished normally and false when timer was stopped
 */
export async function startTimer(time: number) {
  const timeToRefresh = getTimeToRefresh(time);
  if (timeToRefresh < 0) {
    return false;
  }

  manualResolver = createManagedPromise<boolean>();
  timeoutId = setTimeout(() => {
    manualResolver.resolve(true);
  }, timeToRefresh * 1000);

  const result = await manualResolver.promise;
  clearTimeout(timeoutId);
  timeoutId = undefined;

  return result;
}

/**
 * @returns true if time is expired
 */
export function isTokenExpired(time: number): boolean {
  return getTimeToRefresh(time) < 0;
}

/**
 * Checks if there is currently running timer for token refresh
 */
export const isTimerPending = () => !!timeoutId;

/**
 * Stop timer
 * Resolves promise created by startTimer function with false value
 */
export const stopTimer = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = undefined;
  }
  manualResolver.resolve(false);
};

/**
 * Wrapper around promise, which allows
 * to resolve or reject by calling methods directly
 */
type ManagedPromise<T = void> = {
  promise: Promise<T>,
  resolve(value: T): void;
  reject(error: any): void;
};

function createManagedPromise<T>() {
  const p: Partial<ManagedPromise<T>> = {};
  p.promise = new Promise((resolve, reject) => {
    p.resolve = resolve;
    p.reject = reject;
  });
  return p as ManagedPromise<T>;
}

function getTimeToRefresh(time: number): number {
  const minValidity = 5;
  const timeLocal = Math.round(new Date().getTime() / 1000);
  return time - timeLocal - minValidity;
}

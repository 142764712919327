import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import decodeToken from 'src/helpers/jwt';
import { RootState } from 'src/redux/store';
import { getUserInfo, getOrganizationRoles, getOriginalExperienceAutoConnectionUri } from 'src/services/requests/user';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { UserRoles } from 'src/types/roles';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { Role } from 'src/types/validators/OrganizationRolesResponse';

import { UserState } from './types';

type UserInfo = FlowReturn<typeof getUserInfo>;
type OrganizationRoles = FlowReturn<typeof getOrganizationRoles>;

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
    if (!auth.tokens) return rejectWithValue('Missing tokens.');

    const { accessToken } = auth.tokens;

    if (!accessToken) return rejectWithValue('Not found access token.');

    const userId = decodeToken(accessToken).user_id;

    if (!userId) return rejectWithValue('fetchUser: No userId in decoded token');

    const response: UserInfo = await getUserInfo(userId, accessToken);

    if (!response) {
      return rejectWithValue('The UserInfo response is not received.');
    }

    const organizationRoles: OrganizationRoles = await getOrganizationRoles(userId, accessToken);

    if (!organizationRoles || !organizationRoles.items || !organizationRoles.items.length) {
      return rejectWithValue('The organization roles are not retrieved.');
    }

    // Retrieve available roles
    const rolesArr = organizationRoles.items.reduce(
      (acc: Role[], organizationRole) => {
        acc.push(organizationRole.role);
        return acc;
      },
      [],
    );

    let roles = rolesArr.map((role) => {
      switch (role) {
        case Role.LEARNER:
          return UserRoles.LEARNER;
        case Role.ORG_ADMIN:
          return UserRoles.ADMIN;
        case Role.INSTRUCTIONAL_DESIGNER:
          return UserRoles.LEARNING_DESIGNER;
        case Role.FACILITATOR:
          return UserRoles.FACILITATOR;
        default:
          return UserRoles.LEARNER;
      }
    });
    // Remove duplicated roles
    roles = [...new Set(roles)];

    const organizationIds = organizationRoles.items.map((role) => role.organizationId) as number[];

    return {
      user: response,
      roles,
      organizationIds,
    };
  },
);

export const fetchUserCaseHandlers: ThunkCaseHandlers<UserState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (
    state,
    { payload }: PayloadAction<{
      user: UserInfo,
      roles: string[],
      ckPlatformUri: string
      organizationIds: number[]
    }>,
  ) => {
    const {
      user,
      roles,
      ckPlatformUri,
      organizationIds,
    } = payload;

    state.user = {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.username,
      avatarUrl: user?.avatarUrl || undefined,
      roles: roles || [],
      activeRole: roles?.length ? roles[0] : '',
    };

    state.ckPlatformUri = ckPlatformUri;
    state.organizationIds = organizationIds;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

export const fetchOriginalExperienceUri = createAsyncThunk(
  'user/fetchOriginalExperienceUri',
  async (_, { getState, rejectWithValue }) => {
    const { auth, user } = getState() as RootState;
    if (!auth.tokens) return rejectWithValue('Missing tokens.');

    const { accessToken } = auth.tokens;

    if (!accessToken) return rejectWithValue('Not found access token.');
    if (!user.user) return rejectWithValue('Missing user.');
    if (!user.user.id) return rejectWithValue('Missing user id.');

    type OriginalExperienceAutoConnectionUri = FlowReturn<typeof getOriginalExperienceAutoConnectionUri>;
    let response: OriginalExperienceAutoConnectionUri;
    try {
      response = await getOriginalExperienceAutoConnectionUri(
        user.user.id.toString(),
        accessToken,
      );
    } catch (err) {
      return rejectWithValue('Failed to fetch original experience auto connection uri');
    }
    if (!response) return rejectWithValue('The OriginalExperienceAutoConnectionUri response is not received.');

    return response.autoConnectionUri;
  },
);

export const fetchOriginalExperienceUriCaseHandlers: ThunkCaseHandlers<UserState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

import { SnapshotIn, types } from 'mobx-state-tree';
import { LearningTypes } from 'src/types/LearningTypes';

const LearningDataModel = types.maybe(types.model('LearningDataModel', {
  id: types.identifier,
  type: types.enumeration(Object.values(LearningTypes)),
  header: types.model({
    ratingValue: types.number,
    courseName: types.string,
    ratingCount: types.number,
    viewsCount: types.number,
    thumbnail: types.string,
  }),
  body: types.model({
    publisher: types.maybe(types.string),
    durationMinutes: types.number,
    dueDate: types.maybe(types.Date),
    progress: types.maybe(types.string),
    description: types.string,
    topics: types.maybe(types.array(types.model({ id: types.string, text: types.string }))),
    overview: types.maybe(types.array(types.model({ id: types.string, text: types.string }))),
    skills: types.array(types.model({ id: types.string, text: types.string })),
    level: types.maybe(types.string),
    authors: types.maybe(types.array(types.string)),
  }),
}));

export type LearningDataModelSnapshot = SnapshotIn<typeof LearningDataModel>;
export default LearningDataModel;

import { EVENTS_PATH_PATTERNS, generateApiUrl, ObjectOfStrings } from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { EventListResponse, validateEventListResponse } from 'src/types/validators/EventListResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

export async function getEvents(
  accessToken: string,
  queryParams: ObjectOfStrings,
): Promise<EventListResponse | undefined> {
  const route = generateApiUrl(EVENTS_PATH_PATTERNS.ROOT, undefined, queryParams);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Events get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Events get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Events get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateEventListResponse(json)) {
    return json;
  }

  console.error('Event list get response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

import { reaction, toJS } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import { SEARCH } from 'src/constants/api';
import { KEY_APP_STATE } from 'src/constants/localStorage';
import { TRACK_SEARCH } from 'src/constants/measurements';
import parseContentItem from 'src/helpers/parseContentItem';
import { getSingleLearningObject, searchContents } from 'src/services/api/content';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';
import sortCategories from 'src/store/ui/library/Sort/sortCategories';
import { FlowReturn } from 'src/types/utils';

import ContentModel, { ContentModelSnapshot } from './ContentModel';
import DetailedContentModel, { createDetailedContentModelFromResponse } from './DetailedContentModel';

/**
 * This store contains loaded content items and dispatches search, when filters change
 */
export const ContentStore = types.model('ContentStore', {
  id: types.identifier,
  contents: types.array(ContentModel),

  keywords: types.string,
  typeFilters: types.array(types.string),
  skillFilters: types.array(types.string),

  sortOption: types.string,

  totalResultCount: 0,
  pageSize: 20,
  pagesCount: 10,
  currentPage: 1,

  fetched: false,

  detailedItem: types.maybe(DetailedContentModel),
  isDetailedItemLoading: false,
})
  .actions((self) => ({

    setKeywords(keywords: string) {
      self.keywords = keywords;
    },

    setTypeFilters(typeFilters: string[]) {
      self.typeFilters.replace(typeFilters);
    },

    setSkills(skills: string[]) {
      self.skillFilters.replace(skills);
    },

    setSortOption(sortOption: string) {
      self.sortOption = sortOption;
    },

    setPage(page: number) {
      self.currentPage = page;
    },

    replaceContents(json: ContentModelSnapshot[]) {
      const contents = json.map((content) => ContentModel.create(content));

      self.contents.replace(contents);
    },

    serialize() {
      const { typeFilters: type, skillFilters: skills } = self;

      return [type, skills].map((item) => toJS(item) as string[]);
    },

  }))
  .actions((self) => ({
    search: flow(function* search() {
      try {
        const token = getToken();

        const [type, skills] = self.serialize();
        type Response = FlowReturn<typeof searchContents>;
        const response: Response = yield searchContents(token, {
          query: self.keywords || '',
          types: type,
          skills,
          languageTag: SEARCH.DEFAULT_LANGUAGE_CODE,
          orderBy: self.sortOption,

        }, `?size=${self.pageSize}&offset=${(self.currentPage - 1) * self.pageSize}`);

        if (!response || !Array.isArray(response.items)) {
          return;
        }

        const parsedContent = response.items.map((item: any) => parseContentItem(item));

        self.replaceContents(parsedContent);
        self.totalResultCount = response.count || 0;
        self.pagesCount = Math.ceil(self.totalResultCount / self.pageSize);

        MeasurementsClient.track(TRACK_SEARCH, {
          keywords: self.keywords,
          type,
          currentPage: self.currentPage,
          totalResultCount: self.totalResultCount,
        });
      } finally {
        self.fetched = true;
      }
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      // Trigger search only when specific values changed
      reaction(
        () => [
          self.currentPage,
          self.pageSize,
          self.keywords,
          self.sortOption,
          self.typeFilters.slice(),
          self.skillFilters.slice(),
        ],
        self.search,
      );
    },
  }))
  .actions((self) => ({

    fetchDetailedItem: flow(function* fetchDetailedItem(id: string) {
      self.isDetailedItemLoading = true;
      try {
        const token = getToken();

        // Future real implementation
        /*
        type Response = FlowReturn<typeof getSingleLearningObject>;
        const response = yield getSingleLearningObject(token, id);
        if (!response) {
          console.error('Unable to fetch detailed object');
          return;
        }

        self.detailedItem = createDetailedContentModelFromResponse(response);
        */

        // This is temporary implementation
        // For now mocked api endpoint provides learning objects by type, not id
        // Types in new api are different from current (TYPE_WEBSITE => WEBSITE), so we map them
        const type = self.contents
          .filter((item) => item.id === id)
          .map((item) => {
            const t = item.type.toLowerCase();
            if (t.includes('assessment')) {
              return 'ASSESSMENT';
            }
            if (t.includes('audio')) {
              return 'AUDIO';
            }
            if (t.includes('document')) {
              return 'DOCUMENT';
            }
            if (t.includes('interactive')) {
              return 'INTERACTIVE';
            }
            if (t.includes('picture')) {
              return 'PICTURE';
            }
            if (t.includes('training')) {
              return 'TRAINING';
            }
            if (t.includes('video')) {
              return 'VIDEO';
            }
            if (t.includes('website')) {
              return 'WEBSITE';
            }
            return 'VIDEO';
          })[0] || 'VIDEO';
        type Response = FlowReturn<typeof getSingleLearningObject>;
        const response: Response = yield getSingleLearningObject(token, type);
        if (!response) {
          console.error('Unable to fetch detailed object');
          return;
        }

        self.detailedItem = createDetailedContentModelFromResponse(response);
      } catch (e) {
        console.error('Unexpected error while fetching detailed item', e);
      } finally {
        self.isDetailedItemLoading = false;
      }
    }),

  }));

/**
 * Very wrong way to pass token into the store  
 * TODO: Refactor this
 */
function getToken() {
  const tokensAsString = localStorage.getItem(KEY_APP_STATE);
  if (!tokensAsString) {
    throw new Error('Unable to get content(s) because user is not authenticated.');
  }

  const appStore = JSON.parse(tokensAsString);

  return appStore.auth.tokens.accessToken as string;
}

export function createContentStore() {
  return {
    id: ContentStore.name,
    contents: [],
    keywords: '',
    typeFilters: [],
    skillFilters: [],
    sortOption: sortCategories.entries[0].name,
  };
}

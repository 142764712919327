import { Instance, types } from 'mobx-state-tree';

export const SortModel = types.model('SortModel', {
  id: types.identifier,
  name: types.string,
  value: types.string,
});

export interface SortModelInstance extends Instance<typeof SortModel> {}

export const SortCategory = types.model('SortCategory', {
  name: types.identifier,
  entries: types.array(SortModel),
});

import {
  GET_ORIGINAL_EXPERIENCE_AUTO_CONNECTION_URI, GET_USER, generateApiUrl, ORGANIZATION_ROLES_PATH_PATTERNS,
} from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { OrganizationRolesResponse } from 'src/types/validators/OrganizationRolesResponse';
import {
  OriginalExperienceAutoConnectionUriResponse,
} from 'src/types/validators/OriginalExperienceAutoConnectionUriResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import { UserDataResponse/* , validateUserDataResponse */ } from 'src/types/validators/UserDataResponse';

import ConfigClient from '../config/ConfigClient';

export default async function getUserInfo(userId: string, accessToken: string) {
  const route = ConfigClient.get('API_BASE_URL') + GET_USER.URL.replace(GET_USER.KEY_USER_ID, userId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);
  if (!json) {
    return undefined;
  }

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`User request failed with status ${response.status}`, json);
    }

    return json as UserDataResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`User request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`User request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`User request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function getOriginalExperienceAutoConnectionUri(userId: string, accessToken: string) {
  const route = ConfigClient.get('API_BASE_URL')
  + GET_ORIGINAL_EXPERIENCE_AUTO_CONNECTION_URI.URL.replace(GET_ORIGINAL_EXPERIENCE_AUTO_CONNECTION_URI.KEY_USER_ID, userId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({ redirectUrl: '/site/my-activity' }),
  };

  const response = await fetch(route, options);
  if (response.status !== 200) {
    console.error(`Get original experience auto connection uri request failed with status ${response.status}`);

    return;
  }

  const json = await response.json().catch(console.error);
  if (!json) {
    console.error('Unable to retrieve original experience auto connection uri payload');

    return;
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'Get original experience auto connection uri response structure is invalid. Probably API has changed',
      getValidationErrors(),
      json,
    );
  }

  return json as OriginalExperienceAutoConnectionUriResponse;
}

/**
 * Call api to get organization roles for a given user
 */
export async function getOrganizationRoles(
  userId: string,
  accessToken: string,
): Promise<OrganizationRolesResponse | undefined> {
  const route = generateApiUrl(ORGANIZATION_ROLES_PATH_PATTERNS.ROOT, undefined, { userId, organizationType: 'Talent' });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 200) {
    console.error(`Organization roles get request failed with status ${response.status}`);

    return undefined;
  }

  const json: OrganizationRolesResponse = await response.json().catch(console.error);

  if (Object.keys(json).length === 0) {
    console.error('Organization roles get request failed with empty payload');

    return undefined;
  }

  return json;
}

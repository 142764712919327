import {
  Instance, types,
} from 'mobx-state-tree';
import LearningContentStore, {
  createLearningContentStore,
} from 'src/store/domain/TrainingProgram/LearningContent/LearningContentStore';
import ProgramManagementStore from 'src/store/domain/TrainingProgram/ProgramManagement/ProgramManagementStore';
import {
  LearningDetailPageStore, createLearningDetailPageStore,
} from 'src/store/ui/learningDetail/LearningDetailPageStore';
import LibraryPageStore, { createLibraryPageStore } from 'src/store/ui/library/LibraryPageStore';
import { createSettingsPageStore, SettingsPageStore } from 'src/store/ui/settings/SettingsPageStore';
import ProgramPageStore, { createProgramPageStore } from 'src/store/ui/trainingProgram/ProgramPage/ProgramPageStore';

import AuthStore from './domain/Auth/AuthStore';
import { ContentStore, createContentStore } from './domain/ContentStore/ContentStore';
import { createQueryStore, QueryStore } from './domain/QueryStore/QueryStore';
import ThemeStore from './domain/Theme/ThemeStore';

export type Store = Instance<typeof RootStore>;

export const RootStore = types.model('RootStore', {
  // UI
  settingsPage: SettingsPageStore,
  libraryPage: LibraryPageStore,
  learningDetail: LearningDetailPageStore,
  programPage: ProgramPageStore,
  learningContent: LearningContentStore,

  // Domain
  auth: AuthStore,
  theme: ThemeStore,
  content: ContentStore,
  query: QueryStore,
  programManagement: ProgramManagementStore,
});

export function createRootStore() {
  const theme = ThemeStore.create({ id: ThemeStore.name });
  const query = createQueryStore();
  const auth = AuthStore.create({ id: AuthStore.name });
  const content = createContentStore();

  const createInitialState = () => ({
    // UI
    settingsPage: createSettingsPageStore(theme.id),
    libraryPage: createLibraryPageStore(content.id, query.id),
    learningDetail: createLearningDetailPageStore(content.id),
    programPage: createProgramPageStore(auth.id),
    programManagement: {},
    learningContent: createLearningContentStore(auth.id),

    // Domain
    auth,
    theme,
    content,
    query,
  });

  const rootStore: Instance<typeof RootStore> = RootStore.create(createInitialState());

  /**
   * uncomment this onSnapshot call and import the method from 
   * mobx-state-tree to debug the store from the browser console.
   */
  // eslint-disable-next-line
  // onSnapshot(rootStore, console.log);

  return rootStore;
}

import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  createImage, createImageCaseHandlers, fetchImageUrl, fetchImageUrlCaseHandlers,
} from './thunks';
import { ImageState } from './types';
import type { RootState } from '../store';

const initialState: ImageState = {
  status: Status.IDLE,
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // FetchImage
      .addCase(fetchImageUrl.rejected, fetchImageUrlCaseHandlers.handleRejected)

      // CreateImage
      .addCase(createImage.rejected, createImageCaseHandlers.handleRejected)
      .addCase(createImage.fulfilled, createImageCaseHandlers.handleFulfilled)
      .addCase(createImage.pending, createImageCaseHandlers.handlePending);
  },
});

export default imageSlice.reducer;

export const selectImage = (state: RootState) => state.images;

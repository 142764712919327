import { types } from 'mobx-state-tree';

import categories from './filterCategories';
import { FilterCategory, FilterModelInstance } from './FilterModel';

const searchSeparator = ' ';

export const FilterStore = types.model('FilterStore', {
  categories: types.array(FilterCategory),
})
  .actions((self) => ({

    clearFilters() {
      for (const category of self.categories) {
        for (const entry of category.entries) {
          entry.checked = false;
        }
      }
    },

    fromSearchParams(search: string) {
      const ids = search.split(searchSeparator);
      for (const category of self.categories) {
        for (const entry of category.entries) {
          entry.checked = ids.includes(entry.id);
        }
      }
    },

  }))
  .views((self) => ({

    get selectedCount() {
      let count = 0;
      for (const category of self.categories) {
        count += category.selectedCount;
      }
      return count;
    },

    get checkedFilters() {
      return self.categories
        .flatMap((category) => category.entries)
        .filter((entry) => entry.checked) as FilterModelInstance[];
    },

    get checkedTypes() {
      return self.categories[0].checkedNames;
    },

  }))
  .views((self) => ({

    get asSearchParams() {
      return self.checkedFilters
        .map((filter) => filter.id)
        .join(searchSeparator);
    },

  }));

export function createFilterStore() {
  return FilterStore.create({ categories });
}

import {
  GET_IMAGE, POST_IMAGE,
} from 'src/constants/api';
import ConfigClient from 'src/services/config/ConfigClient';
import { getValidationErrors } from 'src/types/validators/ajv';
import {
  ImageResponse,
  validateImageResponse,
} from 'src/types/validators/ImageResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

export async function postImage(
  imageType: string,
  imageData: string,
  accessToken: string,
): Promise<ImageResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL') + POST_IMAGE.URL;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const body = {
    imageType: imageType.split('/')[1],
    imageData: imageData.split(',')[1],
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Image post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Image post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Image post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateImageResponse(json)) {
    return json;
  }

  console.error('Image post response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

export async function getImage(
  fileName: string,
  accessToken: string,
): Promise<ImageResponse | undefined> {
  const route = ConfigClient.get('API_BASE_URL') + GET_IMAGE.URL.replace(GET_IMAGE.KEY_IMAGE_ID, fileName);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Image get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Image get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Image get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateImageResponse(json)) {
    return json;
  }

  console.error('Image get response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

import { autorun, reaction } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import { ContentStore } from 'src/store/domain/ContentStore/ContentStore';
import { QueryStore } from 'src/store/domain/QueryStore/QueryStore';

import { createFilterStore, FilterStore } from './Filters/FilterStore';
import { createKeywordStore, KeywordStore } from './Keywords/KeywordStore';
import { createPaginationStore, PaginationStore } from './PaginationStore';
import { createSortStore, SortStore } from './Sort/SortStore';

export default types.model('LibraryPageStore', {
  keywordsStore: KeywordStore,
  filtersStore: FilterStore,
  sortStore: SortStore,
  paginationStore: PaginationStore,

  contentStore: types.reference(ContentStore),
  queryStore: types.reference(QueryStore),

  initializationStarted: false,
})
  .actions((self) => ({
    init: flow(function* init() {
      // When we have params, search will trigger anyway
      if (!self.initializationStarted && !self.queryStore.queryString) {
        self.initializationStarted = true;

        yield self.contentStore.search();
      }
    }),

    afterCreate() {
      autorun(() => self.contentStore.setTypeFilters(self.filtersStore.checkedTypes));
      autorun(() => self.contentStore.setSortOption(self.sortStore.selectedSortOption.value));
      autorun(() => self.contentStore.setKeywords(self.keywordsStore.keywords));
      autorun(() => self.contentStore.setPage(self.paginationStore.currentPage));

      autorun(() => self.paginationStore.setPageCount(self.contentStore.pagesCount));
      autorun(() => self.paginationStore.onPage(self.contentStore.currentPage));

      reaction(
        () => self.filtersStore.asSearchParams,
        () => {
          self.contentStore.setPage(1);
          self.queryStore.setParam('page', '1');
          self.queryStore.setParam('filters', self.filtersStore.asSearchParams);

          self.queryStore.navigateWithQuery('/library');
        },
      );
      reaction(
        () => self.paginationStore.currentPage,
        () => {
          self.queryStore.setParam('page', self.paginationStore.currentPage.toString());
          self.queryStore.navigateWithQuery('/library');
        },
      );
      reaction(
        () => self.sortStore.selectedSortOption,
        () => {
          self.queryStore.setParam('sort', self.sortStore.selectedSortOption.id.toString());
          self.queryStore.navigateWithQuery('/library');
        },
      );
      autorun(() => self.filtersStore.fromSearchParams(self.queryStore.filters));
      autorun(() => self.keywordsStore.setKeywords(self.queryStore.keywords));
      autorun(() => {
        // prevents from running on pages other than /library
        if (!window.location.pathname.includes('/library')) return;
        if (self.queryStore.page > 0) {
          self.paginationStore.onPage(self.queryStore.page);
        } else {
          self.queryStore.setParam('page', '1');
          self.queryStore.navigateWithQuery('/library');
        }
      });
    },
  }))
  .views((self) => ({

    get totalResultCount() {
      return self.contentStore.totalResultCount;
    },

    get selectedFiltersCount() {
      return self.filtersStore.selectedCount;
    },

    get keywords() {
      return self.keywordsStore.keywords;
    },

    get contents() {
      return self.contentStore.contents;
    },

    get isLoading() {
      return !self.contentStore.fetched;
    },

  }));

export function createLibraryPageStore(content: string, query: string) {
  return {
    keywordsStore: createKeywordStore(query, content),
    filtersStore: createFilterStore(),
    sortStore: createSortStore(),
    paginationStore: createPaginationStore(),

    contentStore: content,
    queryStore: query,
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stopTimer } from 'src/services/api/refreshTokensTimer';
import { Status } from 'src/types/Status';

import {
  fetchOriginalExperienceUri,
  fetchOriginalExperienceUriCaseHandlers,
  fetchUser,
  fetchUserCaseHandlers,
} from './thunks';
import { UserModel, UserState } from './types';
import type { RootState } from '../store';

export const initialState: UserState = {
  status: Status.IDLE,
  organizationIds: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    switchActiveRole: (state, { payload }: PayloadAction<string>) => {
      const roleName = payload;
      if (state.user) {
        const foundRole = state.user.roles.find((role) => role === roleName);
        if (foundRole) {
          state.user.activeRole = foundRole;
        } else {
          console.error('User is not allowed to desired role');
        }
      }
    },
    logoutUser: (state) => {
      state.user = undefined;
      stopTimer();
    },
    setUser: (state, { payload }: PayloadAction<UserModel>) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchUser
      .addCase(fetchUser.pending, fetchUserCaseHandlers.handlePending)
      .addCase(fetchUser.fulfilled, fetchUserCaseHandlers.handleFulfilled)
      .addCase(fetchUser.rejected, fetchUserCaseHandlers.handleRejected)

      // fetchOriginalExperienceUri
      .addCase(fetchOriginalExperienceUri.pending, fetchOriginalExperienceUriCaseHandlers.handlePending)
      .addCase(fetchOriginalExperienceUri.fulfilled, fetchOriginalExperienceUriCaseHandlers.handleFulfilled)
      .addCase(fetchOriginalExperienceUri.rejected, fetchOriginalExperienceUriCaseHandlers.handleRejected);
  },
});

export default userSlice.reducer;

export const {
  switchActiveRole,
  logoutUser,
  setUser,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

/**
 * Format Date for given locale
 * ```js
 * const date = new Date('Oct 21, 2022');
 * formatDate('ru', date); // 21 окт. 2022 г.
 * formatDate('pt', date); // 21 de out. de 2022
 * ```
 */
export function formatDate(locale: string, date: Date, options?: Intl.DateTimeFormatOptions) {
  return Intl.DateTimeFormat(locale, { dateStyle: 'medium', ...options }).format(date);
}

/**
 * Additional parameters for duration formatting functions
 */
export type DurationOptions = Omit<Intl.NumberFormatOptions, 'style' | 'unit'>;

/**
 * Format duration in minutes as hours and minutes for given locale
 * ```js
 * formatDurationMinutes('en', 100) // 1 hr 40 min
 * formatDurationMinutes('ru', 100) // 1 ч 40 мин
 * formatDurationMinutes('pt', 100) // 1 h 40 min
 * ```
 */
export function formatMinutesToHrMin(locale: string, durationMinutes: number, options?: DurationOptions) {
  const minutes = durationMinutes % 60;
  const hours = Math.floor(durationMinutes / 60);

  const minutesFormatter = new Intl.NumberFormat(locale, {
    unitDisplay: 'short',
    ...options,
    style: 'unit',
    unit: 'minute',
  });
  const hoursFormatter = new Intl.NumberFormat(locale, {
    unitDisplay: 'short',
    ...options,
    style: 'unit',
    unit: 'hour',
  });

  const hoursString = hours > 0 ? hoursFormatter.format(hours) : '';
  const minutesString = minutesFormatter.format(minutes);

  return `${hoursString} ${minutesString}`.trim();
}

/**
 * Format duration in seconds as hours, minutes and seconds for given locale
 * ```js
 * formatSecondsToHrMinSec('en', 100) // 1 min 40 sec
 * formatSecondsToHrMinSec('ru', 100) // 1 мин 40 с 
 * formatSecondsToHrMinSec('pt', 100) // 1 min 40 seg
 * ```
 * ----
 * Duration is in seconds according to documentation "3.2 Duration format"  
 * https://confluence.wiley.com/pages/viewpage.action?spaceKey=CK&title=API+Design+Guide
 */
export function formatSecondsToHrMinSec(locale: string, durationSeconds: number, options?: DurationOptions) {
  if (durationSeconds <= 0) return '';

  const seconds = durationSeconds % 60;
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const hours = Math.floor(durationSeconds / 3600);

  const hoursFormatter = new Intl.NumberFormat(locale, {
    unitDisplay: 'short',
    ...options,
    style: 'unit',
    unit: 'hour',
  });
  const minutesFormatter = new Intl.NumberFormat(locale, {
    unitDisplay: 'short',
    ...options,
    style: 'unit',
    unit: 'minute',
  });
  const secondsFormatter = new Intl.NumberFormat(locale, {
    unitDisplay: 'short',
    ...options,
    style: 'unit',
    unit: 'second',
  });

  const hoursString = hours > 0 ? hoursFormatter.format(hours) : '';
  const minutesString = minutes > 0 ? minutesFormatter.format(minutes) : '';
  const secondsString = secondsFormatter.format(seconds);

  return `${hoursString} ${minutesString} ${secondsString}`.trim();
}

/**
 * Format a number according the the given locale.
 * ```js
 * formatNumber('en-US', 1234.56); // '1,234.56'
 * formatNumber('fr-FR', 1234.56); // '1\u202f234,56'
 * ```
 * @param locale
 * @param value 
 * @param options 
 * @returns 
 */
export function formatNumber(locale: string, value: number, options?: Intl.NumberFormatOptions): string {
  const numberFormatter = new Intl.NumberFormat(locale, options);

  return numberFormatter.format(value);
}

/**
 * Format the given number as an integer according to the given locale
 * ```js
 * formatNumber('en-US', 1234); // '1,234'
 * formatNumber('fr-FR', 1234); // '1\u202f234'
 * formatNumber('en-US', 1234.56); // '1,235' 
 * ```
 * @param locale 
 * @param integer 
 * @returns 
 */
export function formatInteger(locale: string, integer: number): string {
  return formatNumber(locale, integer, {
    style: 'decimal',
    maximumFractionDigits: 0,
  });
}

export type FormatPercentOptions = Omit<Intl.NumberFormatOptions, 'style' | 'currencyDisplay' | 'currencySign' | 'currency'>;
/**
 * Format the given number as a percentage.
 * ```js
 * formatPercent('en-US', 0.75); // '0.75%'
 * formatPercent('fr-FR', 0.75); // '0.75\u00a0%' 
 * ```
 * @param locale 
 * @param value 
 * @param options 
 * @returns 
 */
export function formatPercent(locale: string, value: number, options?: FormatPercentOptions): string {
  return formatNumber(locale, value, {
    style: 'percent',
    ...options,
  });
}

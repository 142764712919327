/* eslint-disable key-spacing */
import config from 'src/services/config/config';
import ConfigClient from 'src/services/config/ConfigClient';

// API CONSTANTS
export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_AVATAR_URL = 'avatarUrl';
export const KEY_EXPIRES_IN = 'expires_in';
export const KEY_FIRST_NAME = 'firstName';
export const KEY_ID = 'id';
export const KEY_LAST_NAME = 'lastName';
export const KEY_REFRESH_TOKEN = 'refresh_token';
export const KEY_TOKEN_TYPE = 'token_type';
export const KEY_USER_ID = '%userId';
export const KEY_USERNAME = 'username';
export const KEY_IMAGE_ID = 'filename';
export const KEY_LEARNING_OBJECT_ID = 'learning_object';
const KEY_PROGRAM_ID = '%programId';
const KEY_PROGRAM_STEP_ID = '%stepsId';
const KEY_LO_ID = '%learningObjectId';

export const REFRESH_TOKENS = {
  CLIENT_ID_FRONTEND_CLIENT: 'frontend-client',
  GRANT_TYPE_REFRESH_TOKEN: 'refresh_token',
  KEY_CLIENT_ID: 'client_id',
  KEY_GRANT_TYPE: 'grant_type',
};

// LEARNING OBJECTS
export const SEARCH = {
  DEFAULT_LANGUAGE_CODE: 'en-US',
  URL: '/api/v4/learning-objects/search',
};

// USER API
export const GET_ORIGINAL_EXPERIENCE_AUTO_CONNECTION_URI = {
  KEY_USER_ID,
  URL: '/api/v4/users/%userId/to-ckls-auto-connection-uri',
};

export const GET_USER = {
  KEY_USER_ID,
  URL: '/api/v4/users/%userId/',
};

// IMAGE API
export const POST_IMAGE = {
  URL: '/api/v4/images/',
};

export const GET_IMAGE = {
  KEY_IMAGE_ID,
  URL: `/api/v4/images/${KEY_IMAGE_ID}/`,
};

// TP API
export const GET_PROGRAM = {
  KEY_PROGRAM_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/`,
};

export const GET_PROGRAM_LIST = {
  URL: '/api/v4/training-programs/',
};

export const POST_PROGRAM = {
  URL: '/api/v4/training-programs/',
};

export const PUT_PROGRAM = {
  KEY_PROGRAM_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/`,
};

// TP Steps
export const PUT_PROGRAM_STEP = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/`,
};

export const DELETE_PROGRAM_STEP = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/`,
};

export const POST_PROGRAM_STEP = {
  KEY_PROGRAM_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/`,
};

export const GET_PROGRAM_STEP = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/`,
};

// TP Step LOs
export const POST_LEARNING_OBJECT = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/learning-objects/`,
};

export const DELETE_LEARNING_OBJECT = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  KEY_LEARNING_OBJECT_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/learning-objects/${KEY_LEARNING_OBJECT_ID}/`,
};

export const PUT_LEARNING_OBJECT = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  KEY_LEARNING_OBJECT_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/learning-objects/${KEY_LEARNING_OBJECT_ID}/`,
};

export const GET_LO_LINK = {
  KEY_PROGRAM_ID,
  KEY_PROGRAM_STEP_ID,
  KEY_LO_ID,
  URL: `/api/v4/training-programs/${KEY_PROGRAM_ID}/steps/${KEY_PROGRAM_STEP_ID}/learning-objects/${KEY_LO_ID}/launch/`,
};

// new structure
ConfigClient.init(config);

export type ObjectOfStrings = {
  [key: string]: string
};

type ObjectOfStringsOrNumbers = {
  [key: string]: string | number
};

/**
 * Generates the API URL by replacing the params in the URL patters by the values in the params object parameter.
 * It also concatenates the query params according to the object in the queryParams object parameter.
 * The pathPattern parameter should be one of the path patterns in this file.
 */
export function generateApiUrl(
  pathPattern: string,
  params?: ObjectOfStrings,
  queryParams?: ObjectOfStringsOrNumbers,
): string {
  let path = pathPattern;

  if (params) {
    path = Object.keys(params).reduce(
      (acc, key) => acc.replace(`%${key}`, params[key]),
      pathPattern,
    );
  }

  if (queryParams) {
    path += Object.keys(queryParams).reduce(
      (acc, key, index) => `${acc}${index > 0 ? '&' : ''}${key}=${queryParams[key]}`,
      '?',
    );
  }

  return `${ConfigClient.get('API_BASE_URL')}/api/v4${path}`;
}

const ORGANIZATION_ROLES_API_PATH = '/organization-user-roles';
export const ORGANIZATION_ROLES_PATH_PATTERNS = {
  ROOT: `${ORGANIZATION_ROLES_API_PATH}`,
};

const OPENID_CONNECT_API_PATH = '/openid-connect';
export const OPENID_CONNECT_PATH_PATTERNS = {
  ROOT:   `${OPENID_CONNECT_API_PATH}/`,
  TOKEN:  `${OPENID_CONNECT_API_PATH}/token/`,
};

export const OPEN_CONNECT_API_PARAMS = {
  GRANT_TYPE_AUTHORIZATION_CODE:  'authorization_code',
  KEY_CODE:                       'code',
  KEY_GRANT_TYPE:                 'grant_type',
  KEY_REDIRECT_URI:               'redirect_uri',
};

const USER_API_PATH = '/users';
export const USER_PATH_PATTERNS = {
  ROOT:             `${USER_API_PATH}/`,
  USER:             `${USER_API_PATH}/%userId/`,
  CKLS_URL:         `${USER_API_PATH}/%userId/to-ckls-auto-connection-uri/`,
  LOGIN:            `${USER_API_PATH}/login`,
  RECOVER_PASSWORD: `${USER_API_PATH}/recover-password`,
  CHANGE_PASSWORD:  `${USER_API_PATH}/change-password`,
  SEARCH:           `${USER_API_PATH}/search`,
};

const LO_API_PATH = '/learning-objects';
export const LO_PATH_PATTERNS = {
  ROOT:      `${LO_API_PATH}/`,
  SINGLE_LO: `${LO_API_PATH}/%id`,
  SEARCH:    `${LO_API_PATH}/search/`,
};

const TP_API_PATH = '/training-programs';
export const TP_PATH_PATTERNS = {
  ROOT:           `${TP_API_PATH}/`,
  PROGRAM:        `${TP_API_PATH}/%programId/`,
  PROGRAM_STEPS:  `${TP_API_PATH}/%programId/steps/`,
  STEP:           `${TP_API_PATH}/%programId/steps/%stepId/`,
  STEP_LOS:       `${TP_API_PATH}/%programId/steps/%stepId/learning-objects/`,
  LO:             `${TP_API_PATH}/%programId/steps/%stepId/learning-objects/%learningObjectId/`,
  LO_LAUNCH:      `${TP_API_PATH}/%programId/steps/%stepId/learning-objects/%learningObjectId/launch/`,
};

const IMAGE_API_PATH = '/images';
export const IMAGE_PATH_PATTERNS = {
  ROOT:   `${IMAGE_API_PATH}/`,
  IMAGE:  `${IMAGE_API_PATH}/%imageId/`,
};

const EVENTS_API = '/events';
export const EVENTS_PATH_PATTERNS = {
  ROOT: `${EVENTS_API}/`,
};

const REPORTS_API_PATH = '/reports';
export const REPORTS_PATH_PATTERNS = {
  ROOT:           `${REPORTS_API_PATH}/`,
  POWERBI_ACCESS: `${REPORTS_API_PATH}/powerbi-access/`,
};

const ENROLLMENTS_API_PATH = '/enrollments';
export const ENROLLMENTS_PATH_PATTERNS = {
  ROOT:        `${ENROLLMENTS_API_PATH}/`,
  ENROLLMENT:  `${ENROLLMENTS_API_PATH}/%enrollmentId/`,
};

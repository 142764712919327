import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { searchCaseHandlers, searchLearningObjects } from './thunks';
import { LearningObjectsState } from './types';
import type { RootState } from '../store';

const initialState: LearningObjectsState = {
  status: Status.IDLE,
  learningObjects: [],
  currentPage: 0,
  pageSize: 4,
};

const learningObjectsSlice = createSlice({
  name: 'learningObjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // search
      .addCase(searchLearningObjects.pending, searchCaseHandlers.handlePending)
      .addCase(searchLearningObjects.fulfilled, searchCaseHandlers.handleFulfilled)
      .addCase(searchLearningObjects.rejected, searchCaseHandlers.handleRejected);
  },
});

export default learningObjectsSlice.reducer;

export const selectLearningObjectsSlice = (state: RootState) => state.learningObjects;

// MEASUREMENTS CONSTANTS

// eslint-disable-next-line import/prefer-default-export
export const TRACK_SEARCH_FROM_HEADER = 'Search from header';
export const TRACK_BOOKMARK_CLICKED = 'Bookmark button clicked';
export const TRACK_SEARCH = 'Search';
export const TRACK_OPEN_CONTENT_CARD_FROM_SEARCH = 'Opening a card from the search';
export const TRACK_SORT_SELECT = 'Sorting select change';
export const TRACK_VIDEO_EVENTS = 'Video tracking events';
export const TRACK_VIDEO_PLAYING = 'Video played';
export const TRACK_VIDEO_PAUSED = 'Video Paused';
export const TRACK_VIDEO_PLAYBACK_RATE = 'Video Playback Rate changed';
export const TRACK_VIDEO_SUBTITLE = 'Video Subtitle active';
export const TRACK_INTERACTIVE_EVENTS = 'SCORM tracking events';
export const TRACK_INTERACTIVE_INITIALIZE = 'SCORM resource initialized';
export const TRACK_INTERACTIVE_FINISH = 'SCORM resource finished';
export const TRACK_INTERACTIVE_COMMIT = 'SCORM data commited';
export const TRACK_INTERACTIVE_ERROR = 'SCORM error';
export const TRACK_INTERACTIVE_GET_VALUE = 'SCORM data read';
export const TRACK_INTERACTIVE_SET_VALUE = 'SCORM data changed';

import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { KEY_TOKENS } from 'src/constants/localStorage';
import decodeToken from 'src/helpers/jwt';
import { RootState } from 'src/redux/store';
import { isTokenExpired, startTimer } from 'src/services/api/refreshTokensTimer';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';
import {
  changePassword, getTokens, refreshTokens, recoverPassword, userLogin,
} from 'src/services/requests/auth';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { OpenIdConnectTokenResponse } from 'src/types/requests/OpenIdConnectTokenResponse';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { UserLoginResponse } from 'src/types/validators/UserLoginResponse';

import type { AuthState } from './types';

export const initPendo = (pendoUserUuid: string) => {
  if (pendoUserUuid) {
    MeasurementsClient.init(pendoUserUuid);
  } else {
    console.warn('Unable to init pendo. "pendoUserUuid" field not found in decoded token');
  }
};

interface AuthenticateParams {
  authCode?: string,
  redirectUri?: string,
  username?: string,
  password?: string
}

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async ({
    authCode, redirectUri, username, password,
  }: AuthenticateParams, { rejectWithValue }) => {
    type ResponseWithCredentials = FlowReturn<typeof userLogin>;
    type ResponseWithToken = FlowReturn<typeof getTokens>;

    let response: ResponseWithCredentials | ResponseWithToken | null = null;

    // Authentication with credentials
    if (username && password) {
      response = await userLogin(username, password);
    }

    // Authentication by redirect
    if (authCode && redirectUri) {
      response = await getTokens(authCode, redirectUri) as ResponseWithToken;
    }

    if (!response?.access_token) {
      return rejectWithValue('Unable to fetch tokens. "access_token" field not found in response.');
    }

    const decoded = decodeToken(response.access_token);

    if (!decoded.iss) {
      return rejectWithValue(`Unable to refresh token. "iss" field not found in decoded token, ${JSON.stringify(decoded)}`);
    }

    if (!decoded.exp) {
      return rejectWithValue(`Unable to refresh token. "exp" field not found in decoded token, ${JSON.stringify(decoded)}`);
    }

    startTimer(decoded.exp);

    if (decoded.pendoUserUuid) initPendo(decoded.pendoUserUuid);

    return response;
  },
);

export const authenticateCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<OpenIdConnectTokenResponse | UserLoginResponse>) => {
    state.tokens = {
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token,
      expiresIn: payload.expires_in,
      tokenType: payload.token_type,
    };
    localStorage.setItem(KEY_TOKENS, JSON.stringify(payload));
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (param, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;

    if (!auth.tokens) {
      return rejectWithValue('Not found tokens.');
    }

    const { accessToken, refreshToken } = auth.tokens;

    if (!accessToken || !refreshToken) {
      return rejectWithValue('Not found access and refresh tokens.');
    }

    const decoded = decodeToken(accessToken);

    if (!decoded.iss) {
      return rejectWithValue(`Unable to refresh token. "iss" field not found in decoded token, ${JSON.stringify(decoded)}`);
    }

    if (!decoded.exp) {
      return rejectWithValue(`Unable to refresh token. "exp" field not found in decoded token, ${JSON.stringify(decoded)}`);
    }

    if (isTokenExpired(decoded.exp)) {
      return rejectWithValue('Unable to refresh token. Token is expired');
    }

    type Response = FlowReturn<typeof refreshTokens>;
    const response: Response = await refreshTokens(decoded.iss, refreshToken);

    if (!response) {
      return rejectWithValue('The refresh tokens response is not received');
    }

    startTimer(decoded.exp);

    if (decoded.pendoUserUuid) initPendo(decoded.pendoUserUuid);

    return response;
  },
);

export const refreshAccessTokenCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<OpenIdConnectTokenResponse>) => {
    state.status = Status.SUCCEEDED;
    state.tokens = {
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token,
      expiresIn: payload.expires_in,
      tokenType: payload.token_type,
    };
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};

export const sendRecoverPasswordEmail = createAsyncThunk(
  'forgotPassword',
  async (
    userEmail: string,
  ) => {
    type Response = FlowReturn<typeof recoverPassword>;
    const response: Response = await recoverPassword(userEmail);

    return response;
  },
);

export const sendRecoverPasswordEmailCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
    state.tokens = undefined;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};

interface ResetPasswordParams {
  newPassword: string,
  code: string,
}

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (
    { code, newPassword }: ResetPasswordParams,
  ) => {
    type Response = FlowReturn<typeof changePassword>;
    const response: Response = await changePassword(code, newPassword);

    return response;
  },
);

export const resetPasswordCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
    state.tokens = undefined;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};

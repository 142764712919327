import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { fetchTrainingProgramList, fetchTrainingProgramListCaseHandlers } from './thunks';
import { TrainingProgramListState } from './types';
import type { RootState } from '../store';

const initialState: TrainingProgramListState = {
  status: Status.IDLE,
  trainingPrograms: [],
  currentPage: 1,
  pageSize: 18,
  pageCount: 0,
};

const trainingProgramListSlice = createSlice({
  name: 'trainingProgramList',
  initialState,
  reducers: {
    onPage(state, { payload }: PayloadAction<number>) {
      if (payload > state.pageCount) {
        state.currentPage = state.pageCount;
      } else if (payload < 1) {
        state.currentPage = 1;
      } else {
        state.currentPage = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchTrainingProgramList
      .addCase(fetchTrainingProgramList.pending, fetchTrainingProgramListCaseHandlers.handlePending)
      .addCase(fetchTrainingProgramList.fulfilled, fetchTrainingProgramListCaseHandlers.handleFulfilled)
      .addCase(fetchTrainingProgramList.rejected, fetchTrainingProgramListCaseHandlers.handleRejected);
  },
});

export default trainingProgramListSlice.reducer;

export const { onPage } = trainingProgramListSlice.actions;

export const selectTrainingProgramList = (state: RootState) => state.trainingProgramList;

import { types } from 'mobx-state-tree';
import { createPlatformTheme, defaultPrimaryColor, defaultSecondaryColor } from 'src/config/theme/theme';

/**
 * This store is responsible for keeping reactive values
 * to rebuild theme on the fly when such values change
 */
export default types.model('ThemeStore', {
  id: types.identifier,
  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
})
  .views((self) => ({
    get theme() {
      return createPlatformTheme(self.primaryColor, self.secondaryColor);
    },
  }))
  .actions((self) => ({
    setPrimaryColor(color: string) {
      self.primaryColor = color;
    },
    setSecondaryColor(color: string) {
      self.secondaryColor = color;
    },
  }));

import { SnapshotIn, types } from 'mobx-state-tree';

const ContentModel = types.model('ContentModel', {
  id: types.identifier,
  duration: types.maybe(types.string),
  rating: types.maybe(types.string),
  description: types.string,
  skillList: types.array(types.string),
  thumbnail: types.string,
  title: types.string,
  subtitle: types.string,
  type: types.string,
  redirectUrl: types.string,
  startAt: types.string,
  location: types.string,
});

export type ContentModelSnapshot = SnapshotIn<typeof ContentModel>;
export default ContentModel;

import { useState } from 'react';

import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlertDialog from 'src/components/@common/AlertDialog/AlertDialog';
import { ROUTES, reverseRoute } from 'src/config/routes/routes';
import { KEY_TOKENS } from 'src/constants/localStorage';
import MeasurementsClient from 'src/services/measurements/MeasurementsClient';

interface Props {
  text: string,
  fetchOriginalExperienceUri: () => Promise<string | undefined>,
  onSwitch: () => void,
  setExperienceLoading: (isLoading: boolean) => void
}

function SwitchExperienceMenuItem({
  text, fetchOriginalExperienceUri, onSwitch, setExperienceLoading,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const handleClickOpen = () => setOpenAlertDialog(true);

  const handleCancel = () => setOpenAlertDialog(false);

  // Switch experience
  // TODO: Execution of asynchronous functions in the component should not be, we'll need to be back to refact this block of code
  const switchExperience = async () => {
    await MeasurementsClient.awaitPendoGuideIsCompleted();
    setExperienceLoading(true);

    const uri = await fetchOriginalExperienceUri();

    if (uri) {
      localStorage.removeItem(KEY_TOKENS);

      // Redirect to original experience
      window.location.replace(uri);
    } else {
      onSwitch();
      setExperienceLoading(false);
      // Redirect to error page
      navigate(reverseRoute(ROUTES.error), {
        state: {
          messageText: t('switchExperience.errorMsg', { productCKLS: 'CKLS' }),
        },
      });
    }
  };

  const handleConfirm = () => {
    setOpenAlertDialog(false);
    switchExperience();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <SettingsBackupRestore fontSize="small" />
        </ListItemIcon>

        <ListItemText>{text}</ListItemText>
      </MenuItem>

      {openAlertDialog && (
        <AlertDialog
          title={t('switchExperience.title', { productCKLS: 'CKLS' })}
          description={t('switchExperience.description', { productCKLS: 'CKLS' })}
          cancelButtonText={t('switchExperience.cancel')}
          onCancelPress={handleCancel}
          confirmButtonText={t('switchExperience.confirm')}
          onConfirmPress={handleConfirm}
          data-testid="SwitchExperienceMenuItem-Dialog-test-id"
        />
      )}
    </>
  );
}

export default SwitchExperienceMenuItem;

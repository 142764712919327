import { autorun } from 'mobx';
import { types } from 'mobx-state-tree';
import ThemeStore from 'src/store/domain/Theme/ThemeStore';

import ColorChangeStore from './ColorChangeStore';

/**
 * This store transfers changes from ui store ColorChange to domain store Theme
 */
export const SettingsPageStore = types.model('SettingsPageStore', {
  colorChange: ColorChangeStore,
  theme: types.reference(ThemeStore),
})
  .actions((self) => ({
    afterCreate() {
      autorun(() => self.theme.setPrimaryColor(self.colorChange.validatedPrimaryColor));
      autorun(() => self.theme.setSecondaryColor(self.colorChange.validatedSecondaryColor));
    },
  }));

export function createSettingsPageStore(theme: string | number) {
  return {
    colorChange: ColorChangeStore.create(),
    theme,
  };
}

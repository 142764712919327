import { toJS } from 'mobx';
import { types, SnapshotIn } from 'mobx-state-tree';
import { ContentStore } from 'src/store/domain/ContentStore/ContentStore';
import { LearningTypes } from 'src/types/LearningTypes';

import LearningDataModel from './LearningDataModel';

export const LearningDetailPageStore = types.model('LearningDetailPageStore', {
  currentId: '',
  contentStore: types.reference(ContentStore),
})

  .actions((self) => ({
    fetchData(id: string) {
      if (id !== self.currentId) {
        self.currentId = id;
        self.contentStore.fetchDetailedItem(id);
      }
    },
  }))

  .views((self) => ({
    get isLoading() {
      return self.contentStore.isDetailedItemLoading;
    },

    get data() {
      const content = self.contentStore.detailedItem;
      if (!content) return;

      return LearningDataModel.create({
        id: content.id,
        type: content.type as LearningTypes,
        header: {
          ratingValue: 4,
          courseName: content.title,
          ratingCount: 123,
          viewsCount: 456,
          thumbnail: content.thumbnail,
        },
        body: {
          durationMinutes: (content.estimatedDuration || 0) / 60,
          description: content.description,
          authors: toJS(content.authors),
        },
      });
    },

  }));

export function createLearningDetailPageStore(content: string): SnapshotIn<typeof LearningDetailPageStore> {
  return {
    contentStore: content,
  };
}

import { flow, types } from 'mobx-state-tree';
import { getLearningObjectLink, getProgram, getTrainingProgramStep } from 'src/services/api/program';
import AuthStore from 'src/store/domain/Auth/AuthStore';
import TrainingProgramModel from 'src/store/domain/TrainingProgram/ProgramManagement/TrainingProgramModel';
import deserializeProgram, { deserializeStep } from 'src/store/domain/TrainingProgram/ProgramManagement/trainingProgramService';
import { FlowReturn } from 'src/types/utils';

const ProgramPageStore = types.model('ProgramPageStore', {
  currentProgram: types.maybeNull(TrainingProgramModel),
  programLoadingStatus: types.optional(types.enumeration(['empty', 'loading', 'loaded', 'error']), 'empty'),
  authStore: types.reference(AuthStore),
  showApiErrDialog: false,
})
  .actions((self) => ({
    setShowApiErrDialog(show: boolean) {
      self.showApiErrDialog = show;
    },
  }))
  .actions((self) => ({
    fetchProgram: flow(function* fetchProgram(programId: string) {
      try {
        if (!self.authStore.tokens || !self.authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }
        self.programLoadingStatus = 'loading';
        type Response = FlowReturn<typeof getProgram>;
        const response: Response = yield getProgram(
          programId,
          self.authStore.tokens.accessToken,
        );

        if (!response) {
          self.programLoadingStatus = 'error';
          throw new Error('Tp not found');
        }
        self.currentProgram = deserializeProgram(response);
        self.programLoadingStatus = 'loaded';
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);
      }
    }),
    fetchStep: flow(function* fetchStep(stepId: string, order: number) {
      try {
        if (!self.authStore.tokens || !self.authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }
        if (!self.currentProgram || !self.currentProgram.id) {
          throw new Error('Current program does not exisits or is yet to be created');
        }
        type Step = FlowReturn<typeof getTrainingProgramStep>;
        const response: Step = yield getTrainingProgramStep(
          self.currentProgram.id,
          stepId,
          self.authStore.tokens.accessToken,
        );
        if (!response) {
          throw new Error('Failed to fetch step');
        }
        self.currentProgram.stepsMap.put(deserializeStep(response, order, true));
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);
      }
    }),
    fetchSsoLink: flow(function* fetchSsoLink(programId: string, stepId: string, learningObjectId: string) {
      try {
        if (!self.authStore.tokens || !self.authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }
        type Step = FlowReturn<typeof getLearningObjectLink>;
        const response: Step = yield getLearningObjectLink(
          programId,
          stepId,
          learningObjectId,
          self.authStore.tokens.accessToken,
        );
        if (!response) {
          throw new Error('Failed to fetch sso');
        }
        return response;
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);
        return null;
      }
    }),
  }))
  .actions((self) => ({
    /**
     * Load the program with the given id as current, and fetch the content data for its first step.
     */
    fetchInitialPageData: flow(function* fetchInitialPageData(programId: string) {
      if (!self.authStore.tokens || !self.authStore.tokens.accessToken) {
        throw new Error('User is not logged in');
      }
      if (self.programLoadingStatus !== 'loaded' || self.currentProgram?.id !== programId) {
        yield self.fetchProgram(programId);
      }
    }),
  }));

export default ProgramPageStore;

export function createProgramPageStore(auth: string) {
  return {
    authStore: auth,
  };
}

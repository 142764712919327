import { types } from 'mobx-state-tree';

/**
 * This store manages state of the keyword inputs
 */
export default types.model('KeywordInputStore', {
  value: '',
  submitTrigger: 0,
})
  .actions((self) => ({

    setValue(value: string) {
      self.value = value;
    },

    submit() {
      self.submitTrigger += 1;
    },

  }));

import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/**
 * For all options available, refer to https://www.i18next.com/overview/configuration-options
 */
export const initOptions: InitOptions = {
  debug: false,
  defaultNS: 'common',
  fallbackLng: 'en',
  supportedLngs: ['en', 'pt'],
  interpolation: {
    // Not needed for react as it escapes by default
    escapeValue: false,
  },
  ns: ['common'],
};

i18n
  // Loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // Detect user language
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  .init(initOptions);

export default i18n;

import { types } from 'mobx-state-tree';

const TokensModel = types.model('TokensModel', {
  accessToken: types.maybe(types.string),
  refreshToken: types.maybe(types.string),
  expiresIn: types.maybe(types.number),
  tokenType: types.maybe(types.string),
});

export default TokensModel;

import { flow, types } from 'mobx-state-tree';
import {
  postTrainingProgramStep, postTrainingProgram, putTrainingProgram,
  deleteTrainingProgramStep, getTrainingProgramStep, putTrainingProgramStep,
  getImage, postImage, getProgram, postLearningObjectToStep, deleteLearningObjectFromStep,
} from 'src/services/api/program';
import { AuthStoreInstance } from 'src/store/domain/Auth/AuthStore';
import ProgramData, { StepData } from 'src/types/TrainingProgram';
import { FlowReturn } from 'src/types/utils';
import { TrainingProgramRequest } from 'src/types/validators/TrainingProgramRequest';
import { TrainingProgramResponse } from 'src/types/validators/TrainingProgramResponse';
import { TrainingProgramStepResponse } from 'src/types/validators/TrainingProgramStepResponse';

import TrainingProgramModel, { TrainingProgramStepSnapshot } from './TrainingProgramModel';
import deserializeProgram, { deserializeStep } from './trainingProgramService';

const ProgramManagementStore = types.model('ProgramManagementStore', {
  currentProgram: types.maybeNull(TrainingProgramModel),
  showApiErrDialog: false,
  firstStepCreated: false,
})
  .actions((self) => ({
    setCurrentProgram(jsonResponse: TrainingProgramResponse) {
      self.currentProgram = deserializeProgram(jsonResponse);
    },
    setShowApiErrDialog(show: boolean) {
      self.showApiErrDialog = show;
    },
    addStep(jsonResponse: TrainingProgramStepResponse) {
      if (!self.currentProgram) {
        throw new Error('Unalbe to add step, no current program');
      }
      self.firstStepCreated = true;
      self.currentProgram.stepsMap.put({
        ...deserializeStep(jsonResponse, self.currentProgram.steps.length, true),
      });
    },
    clearCurrentProgram() {
      self.currentProgram = null;
    },
  }))
  .actions((self) => ({
    /**
     * Get the program by id and set it to current program
     */
    getProgram: flow(function* fetchProgram(programId: string, authStore: AuthStoreInstance) {
      try {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }
        type Response = FlowReturn<typeof getProgram>;
        const response: Response = yield getProgram(
          programId,
          authStore.tokens.accessToken,
        );
        if (!response) {
          throw new Error('Failed to get Training Program');
        }
        self.setCurrentProgram(response);
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);
      }
    }),
    /**
     * Create a new program with a title and set it to current program
     */
    createProgram: flow(function* createProgram(programData: ProgramData, authStore: AuthStoreInstance) {
      try {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        programData.languageTag = 'en-US';
        // Add organizationIds in payload
        if (authStore.organizationIds.length) {
          programData.organizationIds = authStore.organizationIds;
        }
        const programBody = programData as TrainingProgramRequest;

        type Program = FlowReturn<typeof postTrainingProgram>;
        const response: Program = yield postTrainingProgram(programBody, authStore.tokens.accessToken);
        if (!response) {
          throw new Error('Program is not created');
        }

        self.setCurrentProgram(response);
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);
      }
    }),

    /**
     * Update a program and set it to current program
     */
    updateProgram: flow(function* updateProgram(programData: ProgramData, authStore: AuthStoreInstance) {
      try {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        if (!self.currentProgram || !self.currentProgram.id || !self.currentProgram.title || !self.currentProgram.languageTag) {
          throw new Error('Current program is empty');
        }

        programData.title = programData.title || self.currentProgram?.title;
        programData.languageTag = programData.languageTag || self.currentProgram?.languageTag;
        programData.thumbnailPath = programData.thumbnailPath || self.currentProgram?.thumbnailPath;
        const programBody = programData as TrainingProgramRequest;

        type Program = FlowReturn<typeof putTrainingProgram>;
        const response: Program = yield putTrainingProgram(self.currentProgram.id, programBody, authStore.tokens.accessToken);
        if (!response) {
          throw new Error('Program is not updated');
        }

        self.setCurrentProgram(response);

        return true;
      } catch (err) {
        self.setShowApiErrDialog(true);
        console.error(err);

        return false;
      }
    }),
    getStep: flow(
      function* getStep(
        stepId: string,
        authStore: AuthStoreInstance,
        order?: number,
      ) {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }
        if (!self.currentProgram || !self.currentProgram.id) {
          throw new Error('Current program does not exisits or is yet to be created');
        }
        type Step = FlowReturn<typeof getTrainingProgramStep>;
        const response: Step = yield getTrainingProgramStep(
          self.currentProgram.id,
          stepId,
          authStore.tokens.accessToken,
        );

        if (!response) {
          throw new Error('failed to fetch step');
        }

        return deserializeStep(response, order || 1, true);
      },
    ),
    createStep: flow(
      function* createStep({ title, description, learningObjectList }: StepData, authStore: AuthStoreInstance) {
        try {
          if (!authStore.tokens || !authStore.tokens.accessToken) {
            throw new Error('User is not logged in');
          }

          if (!self.currentProgram || !self.currentProgram.id) {
            throw new Error('Current program does not exisits or is yet to be created');
          }

          type Step = FlowReturn<typeof postTrainingProgramStep>;
          const response: Step = yield postTrainingProgramStep(
            { title, description },
            self.currentProgram.id,
            authStore.tokens.accessToken,
          );

          if (!response) {
            throw new Error('Failed to create step');
          }

          for (const contentId of learningObjectList.learningObjectsToAdd) {
            yield postLearningObjectToStep(
              self.currentProgram?.id as string,
              response.id,
              contentId,
              authStore.tokens?.accessToken as string,
            );
          }

          self.addStep(response);
        } catch (err) {
          self.setShowApiErrDialog(true);
          console.error(err);
        }
      },
    ),
    updateStep: flow(
      function* updateStep({
        id, title, description, learningObjectList,
      }: StepData, authStore: AuthStoreInstance) {
        try {
          if (!authStore.tokens || !authStore.tokens.accessToken) {
            throw new Error('User is not logged in');
          }

          if (!self.currentProgram || !self.currentProgram.id) {
            throw new Error('Current program does not exisits or is yet to be created');
          }

          const stepIdx = self.currentProgram.steps.findIndex((elt) => elt.id === id);
          if (stepIdx === -1) {
            throw new Error(`Can not find step with id ${id}`);
          }
          for (const contentId of learningObjectList.learningObjectsToAdd) {
            yield postLearningObjectToStep(
              self.currentProgram?.id as string,
              id as string,
              contentId,
              authStore.tokens?.accessToken as string,
            );
          }
          for (const contentId of learningObjectList.learningObjectsToRemove) {
            yield deleteLearningObjectFromStep(
              self.currentProgram?.id as string,
              id as string,
              contentId,
              authStore.tokens?.accessToken as string,
            );
          }

        type Step = FlowReturn<typeof putTrainingProgramStep>;
        const response: Step = yield putTrainingProgramStep(
          self.currentProgram.id,
          id as string,
          authStore.tokens.accessToken,
          { title, description, learningObjectIds: learningObjectList.orderedLearningObjectsList },
        );

        if (!response) {
          throw new Error('Could not update step');
        }
        self.currentProgram.stepsMap.put(deserializeStep(response, stepIdx, true));
        } catch (err) {
          self.setShowApiErrDialog(true);
          console.error(err);
        }
      },
    ),
    moveStep(stepId: string, newIndex: number) {
      if (!self.currentProgram || !self.currentProgram.id) {
        throw new Error('Current program does not exisits or is yet to be created');
      }

      const stepToMove = self.currentProgram.stepsMap.get(stepId);
      if (!stepToMove) {
        throw new Error('No step exists with given id');
      }
      if (newIndex === stepToMove.order) {
        return;
      }
      // Make a copy of the step array and order it correctly accordingly
      const steps = self.currentProgram.steps.slice();
      steps.splice(stepToMove.order, 1);
      steps.splice(newIndex, 0, stepToMove);
      // Then update the order property on each step according the the position in the new ordered array
      steps.forEach((step, index) => {
        step.order = index;
      });
    },
    removeStep: flow(
      function* removeStep(stepId: string, authStore: AuthStoreInstance) {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        if (!self.currentProgram || !self.currentProgram.id) {
          throw new Error('Current program does not exisits or is yet to be created');
        }
        type Response = FlowReturn<typeof deleteTrainingProgramStep>;
        const response: Response = yield deleteTrainingProgramStep(
          self.currentProgram.id,
          stepId,
          authStore.tokens.accessToken,
        );

        if (!response) {
          throw new Error('Failed to delete step');
        }

        self.currentProgram.stepsMap.delete(stepId);
      },
    ),

    /**
     * Post a new thumbnail for the training program
     */
    postThumbnail: flow(function* postThumbnail(imageData: string, imageType: string, authStore: AuthStoreInstance) {
      try {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        type Thumbnail = FlowReturn<typeof postImage>;
        const response: Thumbnail = yield postImage(imageType, imageData, authStore.tokens.accessToken);
        if (!response) {
          throw new Error('Thumbnail was not uploaded');
        }

        return response;
      } catch (err) {
        console.error(err);

        return undefined;
      }
    }),

    getThumbnail: flow(function* getThumbnail(fileName: string, authStore: AuthStoreInstance) {
      try {
        if (!authStore.tokens || !authStore.tokens.accessToken) {
          throw new Error('User is not logged in');
        }

        // TODO: Temporary solution to not show a lot of erros in console, remove this when this endpoint stop accept wrong data
        if (fileName !== 'https://foo-bar.com/some_image.png') {
          type Thumbnail = FlowReturn<typeof getImage>;
          const response: Thumbnail = yield getImage(fileName, authStore.tokens.accessToken);
          if (!response) {
            throw new Error('Thumbnail not found');
          }

          return response.url;
        }
      } catch (err) {
        console.error(err);
      }
    }),
  }))
  .views((self) => ({
    get isStepsReordered() {
      return self.currentProgram?.stepsMap.keys() !== this.reorderedStepIds;
    },

    get reorderedStepIds() {
      return self.currentProgram?.steps.map((step: TrainingProgramStepSnapshot) => step.id);
    },
  }));

export default ProgramManagementStore;

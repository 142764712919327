import {
  Suspense, useState, useMemo, useEffect,
} from 'react';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { observer } from 'mobx-react-lite';
import { useIdleTimer } from 'react-idle-timer';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { BrowserRouter, useNavigate, useMatch } from 'react-router-dom';
import { PROMPT_TIMEOUT_END_SESSION, TIMEOUT_END_SESSION } from 'src/constants/idleTimer';

import BackdropLoading from './components/@common/BackdropLoading/BackdropLoading';
import ConnectLoading from './components/@common/ConnectLoading/ConnectLoading';
import DisconnectSessionDialog from './components/@common/DisconnectSessionDialog/DisconnectSessionDialog';
import MainHeader from './components/@common/MainHeader/MainHeader';
import Router from './config/routes/Router';
import { ROUTES, reverseRoute } from './config/routes/routes';
import decodeToken from './helpers/jwt';
import { clearTokens, retrieveLocalStorageTokens, setAuthInitialized } from './redux/auth/slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { store as reduxStore } from './redux/store';
import { logoutUser } from './redux/user/slice';
import { fetchUser } from './redux/user/thunks';
import { refreshTokens } from './services/requests/auth';
import { StoreProvider as MobXStoreProvider } from './store';
import useStore from './store/useStore';

const authRoute = reverseRoute(ROUTES.auth);

const App = observer(() => {
  const timeout = TIMEOUT_END_SESSION;
  const promptTimeout = PROMPT_TIMEOUT_END_SESSION;
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [isExperienceLoading, setIsExperienceLoading] = useState(false);

  const navigate = useNavigate();
  const store = useStore();
  const isAuthPage = useMatch(authRoute);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  useMemo(() => store.query.setNavigateFunction(navigate), [store.query, navigate]);

  const { theme } = store.theme;
  const { isUserAuthenticated, isIdleDialogEnabled } = store.auth;

  const onPrompt = () => {
    if (isUserAuthenticated && isIdleDialogEnabled) {
      setOpenAlertDialog(true);
    }
  };

  const { reset } = useIdleTimer({
    timeout,
    promptBeforeIdle: promptTimeout,
    startOnMount: true,
    crossTab: true,
    onPrompt,
  });

  const handleReset = () => {
    setOpenAlertDialog(false);
    reset();
  };

  const handleLogoutUser = () => {
    setOpenAlertDialog(false);
    store.auth.logoutUser();
    dispatch(clearTokens());
    dispatch(logoutUser());
    window.location.reload();
  };

  const setExperienceLoading = (isLoading: boolean) => {
    setIsExperienceLoading(isLoading);
  };

  useEffect(() => {
    // INITIALIZE AUTH
    if (!auth.tokens || !auth.tokens.accessToken || !auth.tokens.refreshToken) {
      dispatch(retrieveLocalStorageTokens());
      return;
    }

    const decoded = decodeToken(auth.tokens.accessToken);
    if (!decoded.iss) {
      dispatch(setAuthInitialized(true));
      return;
    }

    (async (iss, refreshToken) => {
      await refreshTokens(iss, refreshToken);
      await dispatch(fetchUser());

      dispatch(setAuthInitialized(true));
    })(decoded.iss, auth.tokens.refreshToken);
  }, [dispatch, auth]);

  // Suspense: here app catches the suspense from page in case translations are not yet loaded
  return (
    <Suspense fallback={(!isAuthPage) ? <BackdropLoading /> : null}>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <div>
          {!isAuthPage && (
            <MainHeader
              loggedUser={store.auth.user}
              logoutUser={handleLogoutUser}
              fetchOriginalExperienceUri={store.auth.fetchOriginalExperienceUri}
              switchActiveRole={store.auth.switchActiveRole}
              setExperienceLoading={setExperienceLoading}
            />
          )}

          {openAlertDialog && (
            <DisconnectSessionDialog
              logoutUser={handleLogoutUser}
              reset={handleReset}
            />
          )}

          {isExperienceLoading && (
            <ConnectLoading />
          )}

          <Router />
        </div>
      </ThemeProvider>
    </Suspense>
  );
});

export default function WrappedApp() {
  /**
   * As of today, we are using both MobX State Tree and Redux state management libraries.
   * This is because we are aiming to migrate the whole state management of the application to use Redux, but we will do that in parts.
   * Therefore, while we are not able to complete the migration, we will keep both in place.
   * 
   * If you are supposed to create new stores and state management modules, please use Redux.
   */
  return (
    <MobXStoreProvider>
      <ReduxStoreProvider store={reduxStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxStoreProvider>
    </MobXStoreProvider>
  );
}

import { INVALID_TYPE, LEARNING_OBJECT_TYPE } from 'src/constants/filters';
import { LearningTypes } from 'src/types/LearningTypes';

import { formatSecondsToHrMinSec } from './format';

const parseContentType = (value: string) => {
  switch (value.toLowerCase()) {
    case 'reading_document':
      return 'DOCUMENT';

    default:
      return value;
  }
};

// parses content to the object structure used in this project
const parseContentItem = (item: any) => {
  const splitTitle = item.title.split(' || ');
  const duration = item.metadata?.duration ? formatSecondsToHrMinSec('en-US', Number(item.metadata.duration)) : '';
  const description = item.metadata?.description || '';
  const contentType = item.type === LEARNING_OBJECT_TYPE ? (item.metadata?.type || INVALID_TYPE) : (item.type || INVALID_TYPE);
  const redirectUri = item.autoConnectionUrl || '';
  const thumbnailPath = item.type === LearningTypes.TRAINING ? (item.thumbnailPath || item.thumbnail) : '';
  return {
    id: item.id,
    duration,
    description,
    skillList: [],
    thumbnail: thumbnailPath || '',
    title: splitTitle[0],
    type: parseContentType(contentType),
    redirectUrl: redirectUri,
    subtitle: item.subtitle || '',
    rating: item.rating || '',
    startAt: item.startAt || '',
    location: item.location || '',
  };
};

export default parseContentItem;
